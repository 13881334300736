import React, { useEffect, useState } from 'react'
import { CoachTabelHead } from '../../../components/headers/caochTableHead/CoachTableHead'
import '../../../styles/QuestionTable.css'
import './ManageVideos.css'
import { getHealthCategory, getTutorialsInfo } from '../../../services/common/CommonServices'
import { NoDataFound } from '../../../components/atoms/NoDataFound'
import delteButton from '../../../assets/images/Delete.png'
import { RiEditFill } from "react-icons/ri";
import { Pagination } from '../../../components/modals/pagination/Pagniation'
import { deleteVideo } from '../../../services/admin/AdminServices'
import { BeatOverlayLoader } from '../../../components/loaders/BeatOverlayLoader'
import { toast } from 'react-toastify';
import { somethingWrongText } from '../../../constants/Variables'
import { AddEditVideoTutorialsModal } from '../../../components/modals/addEditVideoTutorialsModal/AddEditVideoTutorialsModal'
import addPng from '../../../assets/images/plusSign.png'
import { DeleteModal } from '../../../components/modals/deleteModal/DeleteModal'



export const ManageVideos = () => {
    const [triggered, setTriggered] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [videoList, setVideoList] = useState([]);
    const [editableData, setEditAbleData] = useState({})
    const [deleteModalShow, setDelteModalShow] = useState(false);
    const [delteItemId, setDeleteItemId] = useState('')
    const recordsPerPage = 7;
    const [nonManipuldatedHeadlCategoryData,setNonManipuldatedHeadlCategoryData]=useState([]);
    const [healthCategory,setHealthCategory]=useState([]);
    


    /****** useEffect *************** */
    let userRole = localStorage.getItem('role');


    useEffect(() => {
        getAllTutorialsInfo();
        handleHealthCategory();
    }, [triggered])

    /***********pagination**********/

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = videoList?.slice(indexOfFirstRecord,
        indexOfLastRecord);
    const nPages = Math.ceil(videoList?.length / recordsPerPage)

    /******helper functions****** */

    const getAllTutorialsInfo = () => {
        setLoading(true);
        const response = getTutorialsInfo();
        response.then((data) => {
            setLoading(false)
            // console.log(data, "its data when fetch all questions")
            if (data?.status === true) {
                setVideoList(data?.tutorialVideos)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                setLoading(false)
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleEditButton = (item) => {
        setShowModal(true);
        setEditAbleData(item)
    }
   
    const handleHealthCategory = () => {
        const response = getHealthCategory()
        response.then((data) => {
            if (data?.status === true) {
                let newArray = []
                setNonManipuldatedHeadlCategoryData(data.data)
                data?.data.forEach((element) => {
                    let obj = {
                        label: element.value,
                        value: element.value,
                        categoryCode: element.categoryCode
                    }
                    newArray.push(obj);
                })
                setHealthCategory(newArray)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }
    const handleDelete = (id) => {
        setDeleteItemId('')
        setDelteModalShow(false);
        const deleteResponse = deleteVideo(id)
        deleteResponse.then((data) => {
            if (data?.status === true) {
                setTriggered(!triggered)
                toast(data?.message, { type: 'success' });
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleDeleteModal = (id) => {
        setDeleteItemId(id)
        setDelteModalShow(true)
    }


    return (
        <>
            <CoachTabelHead
                total={(videoList?.length !== null && videoList?.length !== 0) ? videoList?.length : "0"}
                userName="Videos"
                setModalShow={setShowModal}
                modalShow={showModal}
                logo={addPng}
                text="Add New Video"
                buttonClass="buttonClassforQuestion"
            />

            <div className={`table-wrapper-container`}>
                <div className='table-container'>
                    <div className="divTable manage-video-table">
                        <div className="headRow">
                            <div className="divCell">ID</div>
                            <div className="divCell">Type</div>
                            <div className="divCell">Health Category</div>
                            <div className="divCell" style={{width:'240px'}}>Sub Health Category</div>
                            <div className="divCell">Language
                            </div>
                            <div className="divCell">Description
                            </div>
                            <div className="divCell">Link
                            </div>
                            {
                                userRole === "333" &&
                                <>
                                    <div className="divCell">Edit</div>
                                    <div className="divCell"></div>
                                </>
                            }
                        </div>
                        {
                            currentRecords.length !== undefined && currentRecords.length !== 0 && currentRecords.length !== null ?
                                currentRecords.map((item) => {
                                    return (
                                        <div key={item?.questionId}>
                                            <div className="divRow" key={item?.videoId}>
                                                <div className="divCell">{item?.videoId}</div>
                                                <div className="divCell">{item?.type}</div>
                                                <div className="divCell">{item?.categoryNames.join(',')}</div>
                                                <div className="divCell" style={{width:'240px',wordBreak:'break-all'}}>{item?.subCategoryNames.join(',')}</div>
                                                <div className="divCell">
                                                    {item?.language}
                                                </div>
                                                <div className="divCell client-detail" >
                                                    {item?.description}
                                                </div>
                                                <div className="divCell client-detail" >
                                                    <a href={item?.link} target='_blank' className='linkStyle'>
                                                        {item?.link}
                                                    </a>
                                                </div>
                                                {
                                                    userRole === "333" &&
                                                    <>
                                                        <div className="divCell" onClick={() => handleEditButton(item)}>
                                                            <RiEditFill style={{ color: '#575757', width: '20px', height: '15px' }} />
                                                        </div>
                                                        <div className="divCell" onClick={() => handleDeleteModal(item?.videoId)}>
                                                            <img className="delete" src={delteButton} alt="delete button">
                                                            </img>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <NoDataFound />
                        }
                    </div>
                </div>
            </div>
            <div className='table-pagination-container'>
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage} />
            </div>
            {
                showModal &&
                <AddEditVideoTutorialsModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    triggered={() => setTriggered(!triggered)}
                    editableData={editableData}
                healthCategory={healthCategory}
                nonManipuldatedHeadlCategoryData={nonManipuldatedHeadlCategoryData}
                    setEditAbleData={() => setEditAbleData({})}
                />
            }
            <DeleteModal
                show={deleteModalShow}
                onHide={() => setDelteModalShow(false)}
                handleDelete={() => handleDelete(delteItemId)}
                title="Delete Video"
                message="Are you sure, do you want to delete this video?"
            />
            {loading &&
                <BeatOverlayLoader />
            }

        </>
    )
}
