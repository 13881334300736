import React, { useEffect, useState } from 'react'
import { TextInputField } from '../../components/atoms/TextInputField'
import '../../styles/GlobalStyles.css'
import '../../styles/LoginPage.css'
import logo from '../../assets/images/Logo.png'
import { SimpleButton } from '../../components/atoms/SimpleButton'
import { forgotPassowrd } from '../../services/auth/AuthServices'
// import { checkEmailValidationReg } from '../../constants/Regex'

export const ForgotPassword = () => {
    const [userEmaill, setUserEmail] = useState("")
    const [emailValidation, setEmailValidation] = useState("")
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState({})


    const handleEmail = (key, e) => {
        // console.log(key, e, "its value")
        const inputValue = e.trim().toLowerCase();
        setUserEmail(inputValue)
    }

    const blurHandler = (key, e) => {
        const inputValue = e.trim().toLowerCase();
        let hasError = false;
        if (
            !/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
                inputValue
            )
        ) {
            hasError = true;
        }
        if (hasError) {
            setEmailValidation("Please enter a valid email id!")
        }
        else {
            setEmailValidation("")
        }
    };
    const focusHandler = () => {
        setEmailValidation("")
    }

    const handleSubmit = (e) => {
        if (userEmaill.trim() === "") {
            setEmailValidation("Please fill out Email field.");
            return false;
        }
        const dataObject = {
            email: userEmaill,
        }
        setLoader(true)
        const responseData = forgotPassowrd(dataObject)
        responseData.then(data => {
            console.log(data, 'is data for pas')
            setLoader(false)
            setMessage(data);
            if (data.status === true) {
                setUserEmail("")
            }
            setTimeout(() => {
                setMessage({})
            }, 4000)
        })
            .catch(error => {
                setMessage({ status: true, message: 'Something went wrong! Please try again later.' })
                setLoader(false)
                setTimeout(() => {
                    setMessage({})
                }, 4000)
            })
    };


    return (
        <div className='auth-page-wrapper'>
            <div className='auth-container p-0 m-0'>
                <div className='auth-col auth-col-1 '>
                    <div className='form-field-container'>
                        <p className='auth-page-title'>
                            Forgot Password
                        </p>
                        <p className='auth-page-subtitle'>
                            Enter your email to reset password!
                        </p>
                        <form>
                            <div className='fields-container'>
                                <TextInputField
                                    label="Email"
                                    fieldType="email"
                                    idfor="exampleInputEmail1"
                                    placeholder="mail@tphc.com"
                                    error={emailValidation}
                                    handleChange={handleEmail}
                                    blurHandler={blurHandler}
                                    keyName="email"
                                    focusHandler={focusHandler}
                                    value={userEmaill}
                                    isRequired={true}
                                />
                            </div>
                        </form>
                        <SimpleButton buttonText="Submit" buttonColor="background-purple" width="100%" textColor="#fff" handleButton={handleSubmit} loader={loader} />
                        {message?.message && (
                            <div className="toast-cont">
                                {message.status === true ?
                                    <p className="text-success">{message.message} </p> :
                                    <p className="text-danger">{message.message} </p>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <div className='auth-col auth-col-2'>
                    <div className='logo-container'>
                        <img src={logo} alt="app Logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}
