import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { TextInputField } from '../../components/atoms/TextInputField'
import '../../styles/GlobalStyles.css'
import '../../styles/LoginPage.css'
import logo from '../../assets/images/Logo.png'
import { SimpleButton } from '../../components/atoms/SimpleButton'
import { forgotPassowrd } from '../../services/auth/AuthServices'
// import { checkEmailValidationReg } from '../../constants/Regex'
import { changePassword } from '../../services/auth/AuthServices';

export const ChangePassword = () => {
    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState("")
    const [passwordShown, setPasswordShown] = useState(false);
    const [message, setMessage] = useState({});
    const [loader, setLoader] = useState(false)
    const [searchParams] = useSearchParams();


    /*******   HelperFunctions  *******/
    const navigate = useNavigate();

    useEffect(() => {
        let paramToken = searchParams.get('token')
        if (!paramToken || paramToken === undefined || paramToken == null || paramToken === "") {
            navigate("/")
        }
    }, [searchParams])

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const hanldeInputValue = (key, e) => {
        console.log(key, e, "its new password")
        setPassword(e)
    }

    const blurHandler = (key, e) => {
    };

    const focusHandler = () => {
        setPasswordValidation("")
    }

    const handleSubmit = (e) => {
        const regx =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;
        if (password.trim() === "") {
            setPasswordValidation("Please fill out Email field!");
            return false;
        }
        let response = regx.test(password.trim());
        if (response) {
            setPasswordValidation("");
        } else {
            setPasswordValidation(
                `Password must contain at least 8 characters, at least one lowercase and uppercase letter, at least one symbol and one number.`
            );
            return false;
        }
        const dataObject = {
            token: searchParams.get('token'),
            newPassword: password,
        }
        setLoader(true)
        const responseData = changePassword(dataObject)
        responseData.then(data => {
            // console.log(data, 'its response')
            setLoader(false)
            if (data.status === true) {
                setMessage(data)
                setPassword("")
                setTimeout(() => {
                    navigate("/")
                }, 1000);
            }
            else if (data.status === false) {
                setMessage(data)
            }
            setTimeout(() => {
                setMessage("")
            }, 6000)
        })
            .catch(error => {
                setMessage({ status: false, message: "Something went wrong! Please try later." })
                console.log(error, "its error ")
                setLoader(false)
            })
        setTimeout(() => {
            setMessage("")
        }, 6000)
    };

    return (
        <>
            <div className='auth-page-wrapper'>
                <div className='auth-container p-0 m-0'>
                    <div className='auth-col auth-col-1'>
                        <div className='form-field-container'>
                            <p className='auth-page-title'>
                                Change Password
                            </p>
                            <p className='auth-page-subtitle'>
                                Enter your new password!
                            </p>
                            <form>
                                <div className='fields-container'>
                                    <TextInputField
                                        label=" New password"
                                        fieldType="password" idfor="exampleInputPassword" placeholder="********"
                                        error={passwordValidation}
                                        focusHandler={focusHandler}
                                        blurHandler={blurHandler}
                                        handleChange={hanldeInputValue} keyName="password"
                                        value={password}
                                        togglePasswordVisiblity={togglePasswordVisiblity}
                                        passwordShown={passwordShown}
                                        isRequired={true}
                                    />
                                </div>
                            </form>
                            <SimpleButton buttonText="Submit" buttonColor="background-purple" width="100%" textColor="#fff" handleButton={handleSubmit} loader={loader} />
                            {message?.message && (
                                <div className="toast-cont">
                                    {message.status === true ?
                                        <p className="text-success">{message.message} </p> :
                                        <p className="text-danger">{message.message} </p>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='auth-col auth-col-2'>
                        <div className='logo-container'>
                            <img src={logo} alt="app Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
