import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../BaseModal.css'
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';
import { addHistoryLabTestHealthStatus} from '../../../services/common/CommonServices';


export const AddClinetInfoCommonModal = ({
    show,
    onHide,
    clientId,
    coachId,
    setClientDetail,
    category,
}) => {
    const [filedError, setFieldError] = useState("")
    const [field, setField] = useState("")
    const [loader, setLoader] = useState(false);

    /**********effects***********/

    /*****************helpers******* */

    const handleHide = () => {
        onHide()
    }

    const handleChange = (e) => {
        setField(e.target.value)
        setFieldError("")
    }

    const handleSubmit = () => {
        if (field.trim() === "") {
            setFieldError("This field is required!")
            return false;
        }
        setLoader(true)
        const requestObject = {
            clientId: clientId,
            coachId: coachId,
            type: category,
            description: field,
        }
        const questionAssignResponse = addHistoryLabTestHealthStatus(requestObject)
        questionAssignResponse.then((data) => {
            setLoader(false)
            if (data.status) {
                // console.log(data, "kljkhf hjkh  jh h  jkh ")
                toast(data?.message, { type: 'success', autoClose: 1000 });
                switch (category) {
                    case "history":
                        setClientDetail(prev => [{ ...prev[0], history: [...prev[0].history, { history: data?.data?.description, timestamp: data?.data?.timestamp }] }])
                        break;
                    case "healthStatus":
                        setClientDetail(prev => [{ ...prev[0], healthStatus: [...prev[0].healthStatus, { healthStatus: data?.data?.description, timestamp: data?.data?.timestamp }] }])
                        break;
                    case "labTest":
                        setClientDetail(prev => [{ ...prev[0], labTest: [...prev[0].labTest, { labTest: data?.data?.description, timestamp: data?.data?.timestamp }] }])
                        break;
                    default:
                }
                setField("")
                onHide();
            }
            else {
                toast(data?.message, { type: 'error', autoClose: 2000 });
            }
        })
            .catch((error) => {
                setLoader(false)
                console.log(error?.response?.statusText, 'Something went wrong!')
                toast(error?.response?.statusText || "Something went wrong, please try again!", { type: 'error' });

            })
    }


    return (
        <Modal show={show} onHide={() => handleHide()}
            className='assign-coach-modal'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    Add {category.toLowerCase()==='healthstatus'?'Health Status':category.toLowerCase()==='labtest'?'Lab Test':category.toLowerCase()==='history'?'History':''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='addQuestion-modal-container assignQuestion-modal-container'>
                    <form>
                        <textarea
                            className='text-area text-area-coach'
                            id="w3review"
                            name="w3review"
                            rows="9"
                            value={field}
                            onChange={(e) => handleChange(e)}>
                        </textarea>
                        {filedError &&
                            <div className="error-message-style-container mt-0 pt-0">
                                <small className="text-danger input-label-style error-message-style ">
                                    {" "}
                                    {filedError}
                                </small>
                            </div>
                        }
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    type='submit'
                    disabled={loader}
                    onClick={handleSubmit}
                >
                    {loader ?
                        <BeatLoader color="#7525E8" /> :
                        "SUBMIT"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}