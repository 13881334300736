import React from 'react';
import { RxCaretRight, RxCaretLeft } from "react-icons/rx";
import "./Pagination.css"
import { usePagination, DOTS } from '../../../hooks/usePagination';
import arraow from '../../../assets/images/Icon.svg'


export const Pagination = ({ nPages, currentPage, setCurrentPage, recordsPerPage, siblingCount = 1 }) => {

    const paginationRange = usePagination({
        nPages,
        siblingCount,
        recordsPerPage,
        currentPage,
    });
    // If there are less than 2 times in pagination range we shall not render the component
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    let lastPage = paginationRange[paginationRange.length - 1];

    // const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    const prevPage = () => {
        if (currentPage !== 1)
            setCurrentPage(currentPage - 1)
    }
    const nextPage = () => {
        if (currentPage !== nPages)
            setCurrentPage(currentPage + 1)
    }

    return (
        <>
            <div>
                <nav>
                    <ul className='pagination justify-content-center'>
                        {currentPage > 1 &&
                            <li className="page-item">
                                <a className="page-link previous"
                                    onClick={prevPage}
                                    href='#'>
                                    <img src={arraow} alt="left-arraow" />
                                    {/* <RxCaretLeft style={{ color: '#7525E8', width: '19.22px', height: '19.22px' }} /> */}
                                    <p className='paginatino-button'>Previous</p>
                                </a>
                            </li>
                        }
                        {paginationRange.map(pgNumber => {
                            // If the pageItem is a DOT, render the DOTS unicode character
                            if (pgNumber === DOTS) {
                                return <li className="page-link">&#8230;</li>;
                            }
                            return (
                                <li key={pgNumber}
                                    className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                    <a onClick={() => setCurrentPage(pgNumber)}
                                        className='page-link'
                                        href='#'>
                                        {pgNumber}
                                    </a>
                                </li>
                            )
                        }
                        )
                        }
                        {nPages > currentPage &&
                            <li className={`page-item`}>
                                <a className="page-link next-button"
                                    onClick={nextPage}
                                    href='#'>
                                    <p className='paginatino-button'>Next</p>
                                    <img src={arraow} alt="left-arraow" />
                                    {/* <RxCaretRight style={{ color: '#7525E8', width: '19.22px', height: '19.22px' }} /> */}
                                </a>
                            </li>
                        }
                    </ul>
                </nav>
            </div>

        </>
    )
}