import axios from "axios";
import { httpRequest } from "../../config/baseUrl/BaseUrl";

export const logOut = () => {
    localStorage.removeItem("userState");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");

}

export const signIn = async (requestObject) => {
    const responseDate = await httpRequest.post(`adminsignin`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate;
    // console.log(responseDate);
}

export const forgotPassowrd = async (requestObject) => {
    const responseData = await httpRequest.post('sendForgotPasswordMail_admin', requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseData.data
    // console.log(responseData.data, "its response when during forgot Password")
}

export const changePassword = async (requestObject) => {
    const responseData = await httpRequest.post('resetPassword_web', requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseData.data, "its response when during forgot Password")
    return responseData.data
}

export const editPassword = async (requestObject) => {
    const responseData = await httpRequest.post(`editPassword_web`, requestObject,
        {
            headers: {
                "Content-Type": 'Application/json'
            }
        }
    )
    // console.log(responseData.data, "its response when during forgot Password")
    return responseData.data
}