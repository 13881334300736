import React from 'react'
import '../Header.css';
import './CaochTableHead.css';


export const CoachTabelHead = ({
    total,
    userName,
    setModalShow,
    setShowEditQuestionModel,
    showEditQuestionModel,
    modalShow,
    coachId,
    resetParamsFilter,
    logo,
    freeQuiz,
    text,
    buttonClass }) => {
    let userRole = localStorage.getItem('role');
    return (
        <div className=' header coach-header'>
            <div className="left-box">
                <p>
                    List of {userName}
                </p>
                <p>
                    {total} available {userName}
                </p>
            </div>
            <div className="right-box">
                {userRole === "333" && (userName === "Coaches" || userName === "Questions" || userName === "Videos") &&
                    <button className={`add-coach-button ${buttonClass}`} onClick={() => setModalShow(!modalShow)}>
                        {logo &&
                            <img src={logo} alt="add user" />
                        }
                        <p>
                            {text}
                        </p>
                    </button>
                }
                {userRole === "333" && (userName === "Coaches" || userName === "Questions" || userName === "Videos") && freeQuiz &&
                    <button className={`add-coach-button ${buttonClass}`} onClick={() => setShowEditQuestionModel(!showEditQuestionModel)}>
                        {logo &&
                            <img src={logo} alt="add user" />
                        }
                        <p>
                            Edit Quiz Question
                        </p>
                    </button>
                }
                {coachId &&
                    <button className='add-coach-button' onClick={resetParamsFilter}>
                        <p>
                            View All Clients
                        </p>
                    </button>
                }
            </div>
        </div>
    )
}