import React from 'react'
import './Footer.css'


export const Footer = () => {
    return (
        <>
            <div className='footer-container'>
                <p>
                    © 2024 TPHC. All Rights Reserved.
                </p>
            </div>
        </>
    )
}