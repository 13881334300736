import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { fetchFreeQuizQuestions } from '../../../services/common/CommonServices';
import './EditQuizQuestion.css'
import { RiEditFill } from 'react-icons/ri';
import { AddQuestionModal } from '../addQuestionModal/AddQuestionModal';


export const EditQuizQuestion = ({ show, onHide }) => {
    const [quizQuestions, SetQuizQuestions] = useState([]);
    const [modalShow,setModalShow]=useState(false);
    const [editableQuestion,setEditableQuestion]=useState({})
    useEffect(() => {
        getQuizQuestion()
    }, [modalShow])
    const getQuizQuestion = async () => {
        const result = fetchFreeQuizQuestions();
        result.then((data) => {
            console.log(data)
            SetQuizQuestions(data.data)
        })
    }
    const handleEditButton = (questionData) => {
        // console.log(questionData, "its questino data")
        setModalShow(true)
        setEditableQuestion(questionData)
    }
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
                centered
            >
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                        Edit Quiz Questions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='body-main-container'>
                    <div className='questionContainer headerStyle'>
                                                <div>Question ID</div>
                                                <div>Question Text</div>
                                            </div>
                        { 
                            quizQuestions.length > 0 ?
                                quizQuestions.map((data, index) => {
                                    return (
                                        <>  
                                           
                                            <div className='questionContainer'>
                                                <div>{index+1}</div>
                                                <div>{data.question}</div>
                                                <div>
                                                    <div className="editButton" onClick={()=>handleEditButton(data)} >
                                                            <RiEditFill style={{ color: '#575757', width: '20px', height: '15px' }} />
                                                        </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                :
                                'No data Found!'
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <AddQuestionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                editableQuestion={editableQuestion}
                isFreeQuiz={true}
                setEditableQuestion={setEditableQuestion}
                // triggered={triggered}
                // setTriggered={setTriggered}
            />
        </>
    )
}