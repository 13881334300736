import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../BaseModal.css'
import { capitalizeFirstLetter, formateDateTime } from '../../../utils/Utils';


export const ClientDetailModal = ({
    show,
    onHide,
    clientDetail
}) => {

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    Client Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="common-card client-info-box">
                    <div className="client-detail-wrapper">
                        <div className="item-wrapper">
                            <p className="key">
                                Client ID
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.id}
                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Date of acceptence
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {formateDateTime(clientDetail?.statusUpdateDate)}
                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Email ID
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.primaryEmail}

                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Phone Number
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.phoneNumber}

                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Family Name
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.familyName}
                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Full Name
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.name}
                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <p className="key">
                                Nick Name
                            </p>
                            <div className="overlap-3">
                                <p className="span-wrapper">
                                    {clientDetail?.nickName}

                                </p>
                            </div>
                        </div>
                        <div className="item-wrapper">
                            <div className="sub-item-wrapper">
                                <div className="sub-item-wrapper-1">
                                    <p className="key">
                                        Gender
                                    </p>
                                    <div className="overlap-3">
                                        <p className="span-wrapper">
                                            {capitalizeFirstLetter(clientDetail?.gender)}

                                        </p>
                                    </div>
                                </div>
                                <div className="sub-item-wrapper-2">
                                    <p className="key">
                                        Age
                                    </p>
                                    <div className="overlap-3">
                                        <p className="span-wrapper">
                                            {clientDetail?.age}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item-wrapper address-wrapper">
                            <p className="key">
                                Address
                            </p>
                            <div className="address-overlap-3-wrapper">
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addressApt}
                                    </p>
                                </div>
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addLocation}
                                    </p>
                                </div>
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addPostCode}
                                    </p>
                                </div>
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addCountry}
                                    </p>
                                </div>
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addTown}
                                    </p>
                                </div>
                                <div className="overlap-3">
                                    <p className="span-wrapper">
                                        {clientDetail?.addNotes}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
            </Modal.Footer>
        </Modal>
    )
}