
import React, { useMemo } from 'react';
import { range } from '../utils/Utils';

export const DOTS = '...';

export const usePagination = ({
    nPages,
    siblingCount = 1,
    recordsPerPage,
    currentPage
}) => {
    const paginationRange = useMemo(() => {
        // const nPages = Math.ceil(nPages / recordsPerPage);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;
        /*
          Case 1:
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..nPages]
        */
        if (totalPageNumbers >= nPages) {
            return range(1, nPages);
        }

        /*
            Calculate left and right sibling index and make sure they are within range 1 and nPages
        */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            nPages
        );

        /*
          We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and nPages. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < nPages - 2
        */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < nPages - 2;

        const firstPageIndex = 1;
        const lastPageIndex = nPages;

        /*
            Case 2: No left dots to show, but rights dots to be shown
        */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, nPages];
        }

        /*
            Case 3: No right dots to show, but left dots to be shown
        */
        if (shouldShowLeftDots && !shouldShowRightDots) {

            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                nPages - rightItemCount + 1,
                nPages
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
            Case 4: Both left and right dots to be shown
        */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [nPages, recordsPerPage, siblingCount, currentPage]);

    return paginationRange;
};