import { httpRequest } from '../../config/baseUrl/BaseUrl';

export const addCoach = async (requestObject) => {
    const responseDate = await httpRequest.post(`createCoach`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const fetchAllCoach = async (requestObject) => {
    const responseDate = await httpRequest.get(`fetchAllCoach`, {
        headers: {
            "status": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const toggleActiveInactive = async (requestObject) => {
    const responseDate = await httpRequest.post(`updateCoachStatus`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const deleteUser = async (requestObject) => {
    const responseDate = await httpRequest.delete(`deleteUser`, {
        headers: {
            "userId": requestObject.userId,
            "role": requestObject.role,
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when delete")
    return responseDate.data;
}

export const clientByCoach = async (requestObject) => {
    const responseDate = await httpRequest.delete(`clientByCoach`, {
        headers: {
            "coachId": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const deleteQuestion = async (requestObject) => {
    const responseDate = await httpRequest.delete(`deleteQuestion`, {
        headers: {
            "questionId": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when delete question")
    return responseDate.data;
}

export const addNewQuestion = async (requestObject) => {
    const responseDate = await httpRequest.post(`addQuestion`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when add a new question")
    return responseDate.data;
}

export const updateQuestion = async (requestObject) => {
    const responseDate = await httpRequest.put(`updateQuestion`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when add a new question")
    return responseDate.data;
}
export const updateFreeQuizQuestion=async(requestObject)=>{
    const responseData=await httpRequest.put(`updatefreeQuestion`,requestObject,{
        headers:{
            'Content-Type':'Application/json'
        }
    })
    return responseData.data;
}

export const updateRequestStatus = async (requestObject) => {
    const responseDate = await httpRequest.post(`updateStatus`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const addNewVideo = async (requestObject) => {
    const responseDate = await httpRequest.post(`createTutorialVideo`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}


export const updateVideo = async (requestObject) => {
    const responseDate = await httpRequest.put(`updateTutorialVideo`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const deleteVideo = async (requestObject) => {
    const responseDate = await httpRequest.delete(`deleteTutorialVideo`, {
        headers: {
            "Content-Type": 'Application/json',
            videoId: requestObject
        }
    })
    return responseDate.data;
}





