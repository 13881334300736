import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../../../styles/GlobalStyles.css'
import '../BaseModal.css'
import './addEditAdminContactInfo.css'
import { TextInputField } from '../../atoms/TextInputField';
import BeatLoader from "react-spinners/BeatLoader";
import { contactNumberLengthText, emailValitaionText, requiredText, somethingWrongText } from '../../../constants/Variables';
import { toast } from 'react-toastify';
import { updateContactInfo } from '../../../services/common/CommonServices';
import { regexEmailValidate } from '../../../constants/Regex';



export const AddEditAdminContactInfo = ({
    show,
    onHide,
    triggered,
    editableData,
    setEditableInfo,
}) => {

    const [values, setValues] = useState({
        name: editableData?.name,
        contact: editableData.contact,
        email: editableData?.email,
    })

    const [errorMessageObject, setErrorMessageObject] = useState({
        nameError: '',
        nameError: '',
        emailError: '',
    })
    const [loading, setLoading] = useState(false);

    //helpers****************************************

    const hanldeInputValue = (key, e) => {
        if (key === "email") {
            setValues({ ...values, [key]: e.replace(" ", "") })

        } else if (key === 'contact') {
            const noLettersSymbols = e.replace(/[^0-9]/g, '');
            setValues({ ...values, [key]: noLettersSymbols })
        }
        else {
            let noNumberSymbol = e.replace(/[^a-zA-Z\s]/g, '')
            setValues({ ...values, [key]: noNumberSymbol })
        }
    }

    const handleFocus = (key, e) => {
        if (key === 'name') {
            setErrorMessageObject({ ...errorMessageObject, nameError: '' })
        }
        else if (key === 'email') {
            setErrorMessageObject({ ...errorMessageObject, emailError: '' })
        }
        else {
            setErrorMessageObject({ ...errorMessageObject, contactError: '' })
        }
    }

    const handleBlur = () => {
    }

    const handleHide = () => {
        setEditableInfo();
        onHide();
    }

    const validation = () => {
        if (values.name?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, nameError: requiredText })
            return false;
        }
        else if (values.contact?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, contactError: requiredText })
            return false;
        }
        else if (values.contact.length < 9 || values.contact.length > 11) {
            setErrorMessageObject({ ...errorMessageObject, contactError: contactNumberLengthText })
            return false;
        }
        else if (values.email?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, emailError: requiredText })
            return false;
        }
        else if (!regexEmailValidate.test(values.email?.trim())) {
            setErrorMessageObject({ ...errorMessageObject, emailError: emailValitaionText })
            return false;
        }
        else {
            return true;
        }
    }

    const handleSubmit = () => {
        if (validation()) {
            setLoading(true)
            const requestObject = {
                id: editableData?.id,
                name: values.name,
                contact: values.contact,
                email: values.email,
            }
            const editContactResponse = updateContactInfo(requestObject)
            editContactResponse.then((data) => {
                setLoading(false)
                // console.log(data, 'its response after testimonial')
                if (data?.status) {
                    triggered();
                    toast(data?.message, { type: 'success' });
                    setValues({
                        name: '',
                        contact: '',
                        email: '',
                    })
                    handleHide();
                }
                else {
                    toast(data?.message, { type: 'error' });
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error, "error inthe submit testimonials")
                toast(somethingWrongText, { type: 'error' });
            })
        }
    }


    return (
        <Modal show={show} onHide={handleHide}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    Edit Contact Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Full name*"
                        error={errorMessageObject.nameError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="name"
                        value={values.name}
                        style="modal-input-style"
                    />
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Cotact number*"
                        error={errorMessageObject.contactError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="contact"
                        value={values.contact}
                        style="modal-input-style"
                        min="9"
                        max="11"
                    />
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Email address"
                        error={errorMessageObject.emailError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="email"
                        value={values.email}
                        style="modal-input-style"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                >
                    {loading ?
                        <BeatLoader color="#7525E8" /> :
                        "Update"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}