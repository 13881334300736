import React, { useEffect, useState } from 'react'
import { BeatOverlayLoader } from '../../../components/loaders/BeatOverlayLoader';
import './NotificationPage.css'
import delteButton from '../../../assets/images/Delete.png'
import { somethingWrongText } from '../../../constants/Variables';
import { deleteNotification, getNotification, updateNotiStatus } from '../../../services/common/CommonServices';
import { toast } from 'react-toastify';
import { NoDataFound } from '../../../components/atoms/NoDataFound';
import { formateDateTime } from '../../../utils/Utils';



export const NotificationPage = () => {

    // states********************************************** 
    const [loader, setLoader] = useState(false)
    const [notificationList, setNotificationList] = useState([])
    const [triggered, setTriggered] = useState(false)


    //hooks,asynstorage*************************************
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('userId')


    useEffect(() => {
        if (userId) {
            getAllNotifications();
        }
    }, [triggered])

    useEffect(() => {
        handleUpdateNotificationStatus();
    }, [])

    //Basic functionalities*********************************


    const getAllNotifications = () => {
        setLoader(true)
        const requestObject = {
            userId: userId || '',
            token: token || '',
        }
        const responseData = getNotification(requestObject)
        responseData.then(data => {
            // console.log(data, 'its data')
            if (data.status) {
                setNotificationList(data?.data)
                setLoader(false)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch(error => {
                toast(somethingWrongText, { type: 'error' });
                setLoader(false)
            })
    };

    const handleDeleteNotification = (id) => {
        setLoader(true)
        const requestObject = {
            userid: userId,
            notifictionid: id,
        }
        const responseData = deleteNotification(requestObject)
        responseData.then(data => {
            // console.log(data?.data, "its notif data")
            if (data.status) {
                toast(data?.message, { type: 'success' });
                setTriggered(!triggered)
                setLoader(false)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch(error => {
                toast(somethingWrongText, { type: 'error' });
                setLoader(false)
            })
    };

    const handleUpdateNotificationStatus = () => {
        const responseData = updateNotiStatus({ userId: userId, token: token })
        responseData.then(data => {
            // console.log(data, 'while updating status noti')
        })
            .catch(error => {
                // console.log(error, ' while updating status noti')
            })
    };


    return (
        <>
            <div className="user-info-container notification-wrapper">
                <div className="user-info-subcontainer">
                    {
                        notificationList !== null && notificationList !== undefined && notificationList.length > 0 ?
                            (
                                notificationList?.map((item) => {
                                    return (
                                        <div className='common-card notification-wrapper-box' key={item?.notifictionId}>
                                            <div className='message-wrapper '>
                                                <p>
                                                    {item?.message}
                                                </p>
                                                <p>
                                                    {formateDateTime(item?.timestamp)}
                                                </p>
                                            </div>

                                            <div className='delete-but-container' onClick={() => handleDeleteNotification(item?.notifictionId)}>
                                                <img className="delete" src={delteButton} alt="delete button">
                                                </img>
                                            </div>
                                        </div>
                                    )
                                })
                            ) :
                            (
                                <NoDataFound text={loader && ''} />
                            )
                    }
                </div>
            </div>
            {loader &&
                <BeatOverlayLoader />
            }
        </>

    )
}