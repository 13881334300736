import { httpRequest } from '../../config/baseUrl/BaseUrl';


export const fetchAllClients = async (requestObject) => {
    const responseDate = await httpRequest.get(`allClient`, {
        headers: {
            "status": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const fetchClientByCoach = async (requestObject) => {
    const responseDate = await httpRequest.get(`clientByCoach`, {
        headers: {
            "coachid": requestObject.coachId,
            "role": requestObject.role,
            "status": requestObject.status,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const activeInactiveClient = async (requestObject) => {
    const responseDate = await httpRequest.post(`updateUserStatus`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data)
    return responseDate.data;
}

export const getHealthCategory = async (requestObject) => {
    const responseDate = await httpRequest.get(`getCategory`)
    return responseDate.data;
}

export const assignHelthCatgory = async (requestObject) => {
    const responseDate = await httpRequest.post(`assignCategory`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when assigning a category")
    return responseDate.data;
}
export const assignsubCategory=async(requestObject)=>{
    const responseData=await httpRequest.post(`assignsubCategory`,requestObject,{
        headers:{
            'Content-Type':'Application/json'
        }
    })
    return responseData.data;
}
export const assignCoach = async (requestObject) => {
    const responseDate = await httpRequest.post(`assignCoach`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    // console.log(responseDate.data, "its data when assigning a coach")
    return responseDate.data;
}

export const fetchAllQuestions = async (requestObject) => {
    const responseDate = await httpRequest.get(`getAllQuestionsWithOptions`, {
        headers: {
            "type": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}
export const fetchFreeQuizQuestions=async(requestObject)=>{
    const responseData=await httpRequest.get(`getAllfreeQuestions`,{
        headers:{
            "type":requestObject,
            "Content-Type":"Application/json"
        }
    })
    return responseData.data
}
export const getClientDetail = async (requestObject) => {
    const responseDate = await httpRequest.get(`clientDetails`, {
        headers: {
            "clientid": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const getResponseDate = async (requestObject) => {
    // console.log(requestObject, "its request object")
    const responseDate = await httpRequest.get(`getResponceDate`, {
        headers: {
            "clientid": requestObject,
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}


export const getResponse = async (requestObject) => {
    const responseDate = await httpRequest.get(`clientQuestionairResponse`, {
        headers: {
            "clientid": requestObject.id,
            'submitdate': requestObject.dateTimeItem,
            'type': requestObject.type,
        }
    })
    return responseDate.data;
}


export const assignQuestions = async (requestObject) => {
    const responseDate = await httpRequest.post(`assignQuestion`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const addFeedBack = async (requestObject) => {
    const responseDate = await httpRequest.post(`reviewClient`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const updateAddIntorduction = async (requestObject) => {
    const responseDate = await httpRequest.post(`updateclientIntro`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const addHistoryLabTestHealthStatus = async (requestObject) => {
    const responseDate = await httpRequest.post(`clientTest`, requestObject, {
        headers: {
            "Content-Type": 'Application/json'
        }
    })
    return responseDate.data;
}

export const deleteAssignedQuestion = async (requestObject) => {
    const responseDate = await httpRequest.delete(`deleteuserQuestion`, {
        headers: {
            "Content-Type": 'Application/json',
            userid: requestObject.id,
            questionid: requestObject.quesId,
        }
    })
    return responseDate.data;
}

export const deleteAssignedQuestionArray = async (requestObject) => {
    console.log(requestObject, 'kjkj')
    const responseDate = await httpRequest.post(`deleteuserQuestion`, requestObject, {
        headers: {
            "Content-Type": 'Application/json',
        }
    })
    return responseDate.data;
}

export const getAllTestimonials = async () => {
    const response = await httpRequest.get('getAllTestimonail', {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};

export const addTestimonial = async (requestObject) => {
    const response = await httpRequest.post('createTestimonial', requestObject, {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};

export const updateTestimonial = async (requestObject) => {
    const response = await httpRequest.put('updateTestimonial', requestObject, {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};


export const addHealthCategory = async (requestObject) => {
    const response = await httpRequest.post('createCategory', requestObject, {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};


export const updateHealthCategory = async (requestObject) => {
    const response = await httpRequest.put('updateCategory', requestObject, {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};
export const updateHealthSubCategory=async(requestObject)=>{
    const response=await httpRequest.put('updateSubcategory',requestObject,{
        headers:{
            'Content-Type':'Application/json'
        }
    })
    return response.data
}
export const addSubHealthCategroy=async(requestObject)=>{
    const response=await httpRequest.post('createSubcategory',requestObject,{
        headers:{
            'Content-Type':'Application/json'
        }
    })
    return response.data;
}
export const getAdminContactInfo = async () => {
    const response = await httpRequest.get('getContactInfo', {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};

export const updateContactInfo = async (requestObject) => {
    const response = await httpRequest.put('updateContactInfo', requestObject, {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};

export const getTutorialsInfo = async () => {
    const response = await httpRequest.get('getAllTutorialVideos', {
        headers: {
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};

export const getNotification = async (requestObject) => {
    const response = await httpRequest.get('getAllNotification', {
        headers: {
            "Authorization": `Bearer ${requestObject?.token}`,
            'Content-Type': 'Application/json',
            userId: requestObject?.userId,
        },
    });
    return response.data
};


export const deleteNotification = async (requestObject) => {
    // console.log(requestObject)
    const responseDate = await httpRequest.delete(`deleteNotification`, {
        headers: {
            "Content-Type": 'Application/json',
            userid: requestObject.userid,
            notifictionid: requestObject.notifictionid,
        }
    })
    return responseDate.data;
}

export const getNotificationCount = async (requestObject) => {
    const response = await httpRequest.get('unseenNotification', {
        headers: {
            "Authorization": `Bearer ${requestObject?.token}`,
            'Content-Type': 'Application/json',
            userId: requestObject.userId,
        },
    });
    return response.data
};

export const updateNotiStatus = async (requestObject) => {
    const response = await httpRequest.post('updateNotification', { userId: requestObject.userId }, {
        headers: {
            "Authorization": `Bearer ${requestObject?.token}`,
            'Content-Type': 'Application/json',
        },
    });
    return response.data
};















