import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TextInputField } from '../../components/atoms/TextInputField'
import '../../styles/GlobalStyles.css'
import '../../styles/LoginPage.css'
import logo from '../../assets/images/Logo.png'
import { SimpleButton } from '../../components/atoms/SimpleButton'
import { Link } from 'react-router-dom'
import { signIn } from '../../services/auth/AuthServices'
import { requiredText } from '../../constants/Variables'
// import { checkEmailValidationReg } from '../../constants/Regex'

export const Login = () => {
    //States********************************
    const [userData, setUserData] = useState({
        email: '',
        password: '',
    })
    const [inputError, setInputError] = useState({
        emailError: "",
        passwordError: "",
    })
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);

    //constant, variables, hooks*************
    const navigate = useNavigate();
    let userState = localStorage.getItem("userState");
    let token = localStorage.getItem("token")
    let userRole = localStorage.getItem('role')

    /***********useEffect****************/
    useEffect(() => {
        if (userState === 'true' && token) {
            if (userRole === '333')
                navigate("/app/manage-app", { replace: true })
            else
                navigate("/app/manage-clients", { replace: true })
        }
        else {
            navigate('/', { replace: true })
        }
    }, [userState, token])


    // Normal function **********************
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const hanldeInputValue = (key, e) => {
        setUserData({ ...userData, [key]: e })
    }

    const blurHandler = (key, e) => {
        switch (key) {
            case "email":
                if (!userData.email.trim()) {
                    setInputError({ ...inputError, emailError: requiredText })
                }
                else {
                    const inputValue = e.trim().toLowerCase();
                    if (!/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(inputValue)) {
                        setInputError({ ...inputError, emailError: "Please enter a valid email id!", })
                    } else {
                        setInputError({ ...inputError, emailError: "", })
                    }
                }
                // setUserData({ ...userData, [key]: e })
                break;
            case "password":
                if (!userData.password.trim()) {
                    setInputError({ ...inputError, passwordError: requiredText })
                }
                else {
                    setInputError({ ...inputError, passwordError: "" })
                }
                break;
            default:
                setInputError({ emailError: "", passwordError: '' })
                break;
            // setUserData({ ...userData, [key]: e })
        }
    };

    const focusHandler = () => {
        setInputError({ emailError: "", passwordError: '' })
    }

    const handleSignIn = (e) => {
        // e.preventDefault();
        // console.log(e)
        if (userData.email === "") {
            setInputError({ ...inputError, emailError: requiredText })
            return false;
        }
        if (userData.password === "") {
            setInputError({ ...inputError, passwordError: requiredText })
            return false;
        }
        const dataObject = ({
            email: userData.email,
            password: userData.password,
            // role: userData.role,
        })
        setLoader(true)
        const responseData = signIn(dataObject)
        responseData.then((data) => {
            setLoader(false)
            if (data.data.status === true) {
                localStorage.setItem("user", JSON.stringify(data?.data));
                localStorage.setItem('token', data?.data?.token)
                localStorage.setItem('role', data?.data?.user?.role);
                localStorage.setItem('userId', data?.data?.user?.coachId);
                localStorage.setItem('userEmail', data?.data?.user?.email);
                localStorage.setItem("userState", "true");
            }
            else {
                setMessage(data?.data?.message)
            }
            setTimeout(() => {
                setMessage("")
            }, 4000)
        })
            .catch((error) => {
                setMessage("something went wrong! Please try again")
                setLoader(false)
                setTimeout(() => {
                    setMessage("")
                }, 4000)
            })
    }




    return (
        <>
            <div className='auth-page-wrapper'>
                <div className='auth-container p-0 m-0'>
                    <div className=' auth-col auth-col-1'>
                        <div className='form-field-container'>
                            <p className='auth-page-title'>
                                Sign In
                            </p>
                            <p className='auth-page-subtitle'>
                                Enter your email and password to sign in!
                            </p>
                            <form>
                                <div className='fields-container'>
                                    <TextInputField
                                        label="Email"
                                        fieldType="email"
                                        idfor="exampleInputEmail1" placeholder="mail@tphc.com"
                                        error={inputError.emailError}
                                        focusHandler={focusHandler}
                                        blurHandler={blurHandler}
                                        handleChange={hanldeInputValue}
                                        keyName="email"
                                        value={userData.email}
                                        isRequired={true}
                                    />
                                    <TextInputField
                                        label="Password"
                                        fieldType="password" idfor="exampleInputPassword"
                                        placeholder="Enter your password"
                                        error={inputError.passwordError}
                                        focusHandler={focusHandler}
                                        blurHandler={blurHandler}
                                        handleChange={hanldeInputValue} keyName="password"
                                        value={userData.password}
                                        togglePasswordVisiblity={togglePasswordVisiblity}
                                        passwordShown={passwordShown}
                                        isRequired={true}
                                    />
                                </div>
                            </form>
                            <p className='forget-password-text'>
                                <Link to="/forgot-password" > Forgot Password?</Link>
                            </p>
                            <SimpleButton buttonText="Sign In" buttonColor="background-purple" width="100%" textColor="#fff" handleButton={handleSignIn} loader={loader} />
                            {message && (
                                <div className="toast-cont">
                                    <p className='text-danger'>{message} </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='p-0 auth-col auth-col-2'>
                        <div className='logo-container'>
                            <img src={logo} alt="app Logo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
