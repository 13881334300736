import moment from 'moment'


export function containsNumbersAndSymbols(inputString) {
    const regex = /[0-9!@#$%^&*()_+={}\[\]:;<>,.?~\\|]/;
    return regex.test(inputString);
}

export function continOnlyNumber(inputString) {
    const regex = /^[0-9]+$/;
    return regex.test(inputString)
}


export function resetErrorSuccessMessage(setMessage) {
    setTimeout(() => {
        setMessage({})
    }, 3000)
}


export const range = (start, end) => {
    let length = end - start + 1;
    /*
          Create an array of certain length and set the elements within it from
      start value to end value.
    */
    return Array.from({ length }, (_, idx) => idx + start);
};


export const capitalizeFirstLetter = (s) => {
    return s && s[0].toUpperCase() + s.slice(1);
}

/****************************format date********************/

export const formatDate = (dateItem) => {
    return moment(dateItem).format("Do MMM YYYY")
}
export const formateDateTime = (dateItem) => {
    return moment(dateItem).format("Do MMM YYYY, h:mm a")
}

// export const formatNumber = (num) => {
//     if (num >= 1000) {
//         const suffixes = ['k', 'M', 'G', 'T', 'P', 'E', 'Z'];
//         const suffixIndex = Math.floor(Math.log10(num) / 3);
//         const scaledNum = num / Math.pow(1000, suffixIndex);
//         return scaledNum.toFixed(1) + suffixes[suffixIndex];
//     }
//     return num;
// }

export const formatNumber = (number) => {
    if (number >= 1000000) {
        return (number / 1000000).toFixed(1) + 'm';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'k';
    } else {
        return number.toString();
    }
};