
import React, { memo } from "react";
import Button from 'react-bootstrap/Button';
import BeatLoader from "react-spinners/BeatLoader";


export const SimpleButton = ({ buttonText, buttonColor, width, handleButton, textColor, loader }) => {
    return (
        <>
            <Button
                disabled={loader}
                className={`simplebuttonStyle ${buttonColor}`}
                //  variant="primary"
                onClick={(e) => handleButton ? handleButton(e) : {}}
                size="lg" style={{ width: width, color: textColor }}>
                {loader ?
                    <BeatLoader color="#fff" /> :
                    buttonText
                }
            </Button>
        </>
    )
}