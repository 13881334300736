import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../BaseModal.css'
import { TextInputField } from '../../atoms/TextInputField';
import BeatLoader from "react-spinners/BeatLoader";


export const AddCoachModal = ({
    show,
    onHide,
    coachData,
    setCoachData,
    focusHandler,
    blurHandler,
    hanldeInputValue,
    handleaddcoach,
    inputError,
    loader,
    message }) => {
    const handleSubmit = () => {
        handleaddcoach()
        // if (isClodeModal) {
        //     onHide();
        // }
    }
    return (
        <Modal show={show} onHide={onHide}
            // {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    Add New Coach
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextInputField
                        // label="Name"
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="User Coach*"
                        error={inputError.nameError}
                        focusHandler={focusHandler}
                        blurHandler={blurHandler}
                        handleChange={hanldeInputValue}
                        keyName="name"
                        value={coachData.name}
                        style="modal-input-style"
                    />
                    <TextInputField
                        // label="Designation"
                        fieldType="text"
                        idfor="exampleInputDesignation"
                        placeholder="Designation*"
                        error={inputError.designationError}
                        focusHandler={focusHandler}
                        blurHandler={blurHandler}
                        handleChange={hanldeInputValue}
                        keyName="designation"
                        value={coachData.designation}
                        style="modal-input-style"

                    />
                    <TextInputField
                        // label="Email"
                        fieldType="email"
                        idfor="exampleInputEmail"
                        placeholder="usercoach@gmail.com*"
                        error={inputError.emailError}
                        focusHandler={focusHandler}
                        blurHandler={blurHandler}
                        handleChange={hanldeInputValue}
                        keyName="email"
                        value={coachData.email}
                        style="modal-input-style"

                    />
                    {/* <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue} /> */}
                    <TextInputField
                        // label="Contact"
                        fieldType="tel"
                        idfor="exampleInputNumber"
                        placeholder="8778783455*"
                        error={inputError.contactError}
                        focusHandler={focusHandler}
                        blurHandler={blurHandler}
                        handleChange={hanldeInputValue}
                        keyName="contact"
                        value={coachData.contact}
                        style="modal-input-style"
                    />
                </div>
                {message.message && (
                    <div className="toast-cont">
                        {message.status === true ?
                            <p className="text-success">{message.message} </p> :
                            <p className="text-danger">{message.message} </p>
                        }
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    disabled={loader}
                    onClick={() => handleSubmit()}>
                    {loader ?
                        <BeatLoader color="#7525E8" /> :
                        "Submit"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}