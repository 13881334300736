import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Login } from "../screens/auth/Login";

export const AuthRoutes = ({ role, roleArray, Cmp }) => {
  let userState = localStorage.getItem("userState");
  const navigate = useNavigate();

  useEffect(() => {
    if (!roleArray.includes(role) && role === "222") {
      navigate(-1, { replace: true });
    }
  });

  return <>{userState === "true" ? <Cmp /> : <Login />}</>;
};
