import React, { useState } from 'react'
import { SimpleButton } from '../../components/atoms/SimpleButton';
import '../../styles/GlobalStyles.css'
import { TextInputField } from '../../components/atoms/TextInputField';
import { Header } from '../../components/headers/Header';
import { Footer } from '../../components/footer/Footer';
import { editPassword } from '../../services/auth/AuthServices';
import { BeatOverlayLoader } from '../../components/loaders/BeatOverlayLoader';


export const ResetPassword = () => {

    // states********************************************** 
    const [password, setPassword] = useState("");
    const [currentPassword, setCurrentPassword] = useState("")
    const [passwordValidation, setPasswordValidation] = useState({ forCurrentPassword: "", forPassword: "" })
    const [passwordShown, setPasswordShown] = useState({ forPassword: false, forCurrentPassword: false });
    // const [message, setMessage] = useState("");
    const [message, setMessage] = useState({});
    const [loader, setLoader] = useState(false)


    //hooks,asynstorage*************************************
    const userData = JSON.parse(localStorage.getItem("user"))
    // console.log(userData, "its userdata")
    // console.log(userData.user.email)

    //Basic functionalities*********************************

    const togglePasswordVisiblity = (e) => {
        if (e === "currentPassword") {
            setPasswordShown({ ...passwordShown, ["forCurrentPassword"]: !passwordShown.forCurrentPassword });
        }
        else {
            setPasswordShown({ ...passwordShown, ["forPassword"]: !passwordShown.forPassword })
        }
    };
    const passwordChange = (key, e) => {
        if (key === "currentPassword") {
            setCurrentPassword(e)
        }
        else {
            setPassword(e)
        }
    }
    const blurHandler = (key, e) => {
    };
    const focusHandler = () => {
        setPasswordValidation("")
    }

    const handleSubmit = (e) => {
        let regx =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,50}$/;
        if (currentPassword.trim() === "") {
            setPasswordValidation({ ...passwordValidation, forCurrentPassword: "Please fill out current password field!" });
            return false;
        }
        if (password.trim() === "") {
            setPasswordValidation({ ...passwordValidation, forPassword: "Please fill out password field!" })
            return false;
        }
        let response = regx.test(password.trim());
        if (response) {
            setPasswordValidation({ forPassword: "" });
        } else {
            setPasswordValidation({
                forPassword:
                    `Password must contain at least 8 characters, at least one lowercase and uppercase letter, at least one symbol and one number.`
            });
            return false;
        }

        const dataObject = {
            email: userData.user.email,
            currentPassword: currentPassword,
            newpassword: password,
        }
        setLoader(true)
        const responseData = editPassword(dataObject)
        responseData.then(data => {
            if (data.status === true) {
                setMessage(data)
                setLoader(false)
                setCurrentPassword("")
                setPassword("")
            }
            else if (data.status === false) {
                setMessage(data)
                setLoader(false)
            }
            setTimeout(() => {
                setMessage({})
            }, 4000)
        })
            .catch(error => {
                setMessage({ status: false, message: "Failed to change password! Please try later." })
                setLoader(false)
            })
        setTimeout(() => {
            setMessage({})
        }, 4000)
    };

    return (
        <>
            <div className='dashboard-main-data-wrap'>
                <div className='row p-0 m-0'>
                    <div className='col-12 d-flex'>
                        <div className='form-field-container'>
                            <p className='auth-page-title'>
                                Reset Password
                            </p>
                            <p className='auth-page-subtitle'>
                                Enter your current password and new password!
                            </p>
                            <form>
                                <div className='fields-container'>
                                    <TextInputField label="Current Password"
                                        fieldType="password"
                                        idfor="exampleInputCurrentPassword"
                                        placeholder="Enter current password"
                                        error={passwordValidation.forCurrentPassword}
                                        handleChange={passwordChange}
                                        blurHandler={blurHandler}
                                        keyName="currentPassword"
                                        focusHandler={focusHandler}
                                        passwordShown={passwordShown.forCurrentPassword}
                                        togglePasswordVisiblity={togglePasswordVisiblity}
                                        value={currentPassword}
                                    />
                                    <TextInputField label="New Password"
                                        fieldType="password"
                                        idfor="exampleInputPassword"
                                        placeholder="Enter new password"
                                        error={passwordValidation.forPassword}
                                        handleChange={passwordChange}
                                        blurHandler={blurHandler}
                                        keyName="password"
                                        focusHandler={focusHandler}
                                        passwordShown={passwordShown.forPassword}
                                        togglePasswordVisiblity={togglePasswordVisiblity}
                                        value={password}
                                    />
                                </div>
                            </form>
                            <SimpleButton buttonText="Reset Password" buttonColor="background-purple" width="100%" textColor="#fff" handleButton={handleSubmit} />
                            {message.message && (
                                <div className="toast-cont">
                                    {message.status === true ?
                                        <p className="text-success">{message.message} </p> :
                                        <p className="text-danger">{message.message} </p>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {loader &&
                    <BeatOverlayLoader />
                }
            </div>
        </>

    )
}