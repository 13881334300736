import React, { useEffect, useState } from "react";
import './ClientDetailPage.css'
import assignQuestion from '../../../assets/images/assignQuestion.png'
import { SimpleButton } from "../../../components/atoms/SimpleButton";
import addFeedbackImg from '../../../assets/images/plusSign.png'
import arrow from '../../../assets/images/blackUpArrow.png'
import arrow2 from '../../../assets/images/blackUpArrow.png'
import { useLocation } from "react-router-dom";
import { getClientDetail, getResponse, getResponseDate } from "../../../services/common/CommonServices";
import { toast } from 'react-toastify';
import { AssignQuestionModal } from "../../../components/modals/assignQuestionModal/AssignQuestionModal";
import { AddCoachFeedBackModal } from "../../../components/modals/addCoachFeedBack/AddCoachFeedBackModal";
import { formatDate, formateDateTime } from "../../../utils/Utils";
import { BeatOverlayLoader } from "../../../components/loaders/BeatOverlayLoader";
import { ClientDetailModal } from "../../../components/modals/clientDetailModal/ClientDetailModal";
import editImage from '../../../assets/images/edit.svg'
import { AddClientIntroductionModal } from "../../../components/modals/addClientIntroductionModal/AddClientIntroductionModal";
import { AddClinetInfoCommonModal } from "../../../components/modals/addClientInfoCommonModal/AddClientInfoCommonModal";
import { BeatComponentOverlayLoader } from "../../../components/loaders/BeatComponentOverlayLoader";


export const ClientDetailPage = () => {
    const [loading, setLoading] = useState(false)
    const [tabId, setTabId] = useState(1)
    const [currentQueLoading, setCurrentQueLoading] = useState(true)
    const [triggered, setTriggered] = useState(false);
    const [showAssignQuestinModal, setShowAssignQuestionModal] = useState(false)
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [clientId, setClientId] = useState('')
    const [clientDetail, setClientDetail] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [freeQuestionList, setFreeQuestionList] = useState([]);
    const [currentQuestionnaireData, setCurrentQuestionnaireData] = useState([]);
    const [questionDetailModal, setQuestionDetailModal] = useState({ status: false, id: '' });
    const [freeQuestionDetailMoal,setFreeQuestionDetailModal]=useState({status:false,id:''})
    const [timeSlot, setTimeSlot] = useState(0)
    const [userDetailModalStatus, setUserDetailModalStatus] = useState(false)
    const [userIntroductionModalStatus, setUserIntorductionModalStatus] = useState(false);
    const [showCommonModal, setshowCommonModal] = useState(false);
    const [category, setCategory] = useState('')


    /***********hooks**********/
    const userData = JSON.parse(localStorage.getItem("user"))

    let location = useLocation();

    useEffect(() => {
        setClientId(location?.state?.id)
    }, [])

    useEffect(() => {
        if (clientId) {
            handleResponseDates();
            getFreeQuizReponse();
        }
    }, [clientId])

    useEffect(() => {
        if (clientId != "") {
            handleUserDetail();
        }
    }, [clientId, triggered])


    /**************helpers***************** */

    const handleUserDetail = () => {
        setLoading(true)
        const clientDetailRespose = getClientDetail(clientId)
        clientDetailRespose.then((data) => {
            // console.log(data, 'its data')
            setLoading(false)
            if (data.status) {
                setClientDetail(data?.data || [])
            }
            else {
                setClientDetail([])
                toast("Failed to fetch user data", { type: 'error' });
            }
        }).catch((error) => {
            setLoading(false)
            console.log(error, "Something went wrong, please try again!")
            toast("Something went wrong, please try again!", { type: 'error' });
        })
    }

    const handleResponseDates = () => {
        // console.log(clientId, 'hey its client id')
        const dateResponse = getResponseDate(clientId)
        dateResponse.then((data) => {
            // console.log(data.data, 'its datelist')
            if (data?.status) {
                setDateList(data?.data)
                // setTimeSlot(dateList[0])
                if (data?.data.length > 0) {
                    handleGetResponses(data?.data[0]?.dates)
                }
            }
            else {
                setDateList([])
                toast("Something went wrong, please try again!", { type: 'error' });
            }
        })
            .catch((error) => {
                toast("Something went wrong, please try again!", { type: 'error' });
                console.log(error, 'Something went wrong, please try again!')
            })
    }

    const handleGetResponses = (dateTimeItem) => {
        setTimeSlot(dateTimeItem)
        const requestObject = {
            id: clientId,
            dateTimeItem: dateTimeItem,
            type: 'paid'
        }
        setCurrentQueLoading(true);
        const questionListResponse = getResponse(requestObject);
        questionListResponse.then((data) => {
            console.log(data, "its quix response")
            setCurrentQueLoading(false);
            if (data?.status) {
                setCurrentQuestionnaireData(data?.data)
            }
            else {
                setCurrentQuestionnaireData([])
                toast("Something went wrong, please try again!", { type: 'error' });
            }
        })
            .catch((error) => {
                setCurrentQueLoading(false);
                toast("Something went wrong, please try again!", { type: 'error' });
                console.log(error, 'Something went wrong, please try again!')
            })
    }
    const getFreeQuizReponse = () => {
        const requestObject = {
            id: clientId,
            type: 'free'
        }
        const questionList = getResponse(requestObject);
        questionList.then((res) => {
            console.log(res)
            if (res?.status) {
                console.log(res?.data)
                setFreeQuestionList(res.data)
                console.log(freeQuestionList)
            }
            else {
                setFreeQuestionList([])
                toast("Something went wrong, please try again!", { type: 'error' });
            }
        }).catch((error) => {

        })
    }
    const changeTab = (tabId) => {
        setTabId(tabId)
    }
    const handleCommonModal = (category) => {
        setCategory(category)
        setshowCommonModal(!showCommonModal)
    }

    return (
        <>
            <div>
                <div className="user-detail-header">
                    <div className='header coach-header'>
                        <div className="left-box">
                            <p>
                                {clientDetail[0]?.name}
                            </p>
                            <div className="header-button-wrapper">
                                <SimpleButton
                                    buttonText={clientDetail[0]?.status}
                                    buttonColor={clientDetail[0]?.status === 'Active' ? "buttonAcive" : "buttonInactive"}
                                    width="89px"
                                    textColor="#F4FFF3" />
                                <SimpleButton
                                    buttonText="Details"
                                    buttonColor="buttonAcive"
                                    width="89px"
                                    handleButton={(e) => {
                                        setUserDetailModalStatus(!userDetailModalStatus)
                                    }}
                                    textColor="#F4FFF3" />
                            </div>
                        </div>
                        <div className="right-box">
                            <button
                                className={`add-coach-button `}
                                onClick={() => setShowAssignQuestionModal(!showAssignQuestinModal)}
                            >
                                <img src={assignQuestion} alt="add user" />
                                <p>
                                    Assign Questions
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" user-info-container">
                    <div className="user-info-subcontainer">
                        <div className="first-box">
                            <div className="common-card client-intro-box ps-0 pe-0 d-flex flex-column">
                                <div className="title d-flex justify-content-between padding18 marginBottom15">
                                    <p className="text-wrapper-1">
                                        Intorduction
                                    </p>
                                    <button
                                        className='addfeedback-button'
                                        onClick={() => setUserIntorductionModalStatus(!userIntroductionModalStatus)}
                                    >
                                        <img src={clientDetail[0]?.introduction?.trim() != "" ? editImage : addFeedbackImg}
                                            alt="add intro" />
                                    </button>
                                </div>
                                <pre className="text internal-scroll client-detail-content-container text-style-wrap mb-0">
                                    {clientDetail[0]?.introduction}
                                </pre>
                            </div>
                            <div className="common-card client-history-box ps-0 pe-0 d-flex flex-column">
                                <div className="title d-flex justify-content-between padding18 marginBottom15">
                                    <p className="text-wrapper-1">
                                        History
                                    </p>
                                    <button
                                        className='addfeedback-button'
                                        onClick={() => handleCommonModal("history")}
                                    >
                                        <img src={addFeedbackImg}
                                            alt="add history" />
                                    </button>
                                </div>
                                <div className="d-flex flex-column internal-scroll client-detail-content-container">
                                    {clientDetail[0]?.history?.map((item, index) => {
                                        return (
                                            <div className="mb-4" key={index}>
                                                <p className="text-end date">
                                                    {
                                                        formatDate(item?.timestamp)
                                                    }
                                                </p>
                                                <p className="text text-style-wrap">
                                                    {item?.history}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="second-box">
                            <div className="common-card client-health-box ps-0 pe-0 d-flex flex-column ">
                                <div className="title d-flex justify-content-between padding18 marginBottom15">
                                    <p className="text-wrapper-1">
                                        Health Status
                                    </p>
                                    <button
                                        className='addfeedback-button'
                                        onClick={() => handleCommonModal("healthStatus")}
                                    >
                                        <img src={addFeedbackImg}
                                            alt="add feedback" />
                                    </button>
                                </div>
                                <div className="d-flex flex-column internal-scroll client-detail-content-container">
                                    {clientDetail[0]?.healthStatus?.map((item, index) => {
                                        return (
                                            <div className="mb-4  " key={index}>
                                                <p className="text-end date">
                                                    {
                                                        formatDate(item?.timestamp)
                                                    }
                                                </p>
                                                <p className="text text-style-wrap">
                                                    {item?.healthStatus}
                                                </p>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                            <div className="common-card client-report-box ps-0 pe-0 d-flex flex-column">
                                <div className="title d-flex justify-content-between padding18 marginBottom15">
                                    <p className="text-wrapper-1 ">
                                        Lab Test Results
                                    </p>
                                    <button
                                        className='addfeedback-button'
                                        onClick={() => handleCommonModal("labTest")}
                                    >
                                        <img src={addFeedbackImg}
                                            alt="add feedback" />
                                    </button>
                                </div>
                                <div className="d-flex   internal-scroll client-detail-content-container">
                                    {clientDetail[0]?.labTest?.map((item, index) => {
                                        return (
                                            <div className="mb-4  " key={index}>
                                                <p className="text-end date">
                                                    {
                                                        formatDate(item?.timestamp)
                                                    }
                                                </p>
                                                <p className="text text-style-wrap">
                                                    {item?.labTest}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="third-box">
                            <div className="common-card client-history-box ps-0 pe-0 d-flex flex-column" >
                                <div className="title padding18 marginBottom15">
                                    {/* <p className="text-wrapper-2">
                                        Question History
                                    </p> */}
                                    <div className="date-container">
                                        <p className="text-wrapper-1">
                                            Active Questions
                                        </p>
                                        {/* <p className="text date">
                                            28th Nov, 11:02 AM
                                        </p> */}
                                    </div>
                                </div>
                                <div className="internal-scroll client-detail-content-container">
                                    {clientDetail[0]?.questions.map((item, index) => {
                                        return (
                                            <div className="active-question-wrapper" key={index}>
                                                <p className="question-title">
                                                    Question {index + 1}:
                                                </p>
                                                <p className="text">
                                                    {item?.question}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="common-card client-coach-feedback-box ps-0 pe-0 d-flex flex-column">
                                <div className="title">
                                    <div className="feadback-header">
                                        <p className="text-wrapper-2">
                                            Coach Feedback
                                        </p>
                                        <button
                                            className='addfeedback-button'
                                            onClick={() => setShowFeedbackModal(!showFeedbackModal)}
                                        >
                                            <img src={addFeedbackImg}
                                                alt="add feedback" />
                                        </button>
                                    </div>
                                </div>
                                <div className="feedback-list-wrapper internal-scroll">
                                    {clientDetail[0]?.Reviews?.reverse()?.map((item, index) => {
                                        return (
                                            <div className="mb-4" key={index}>
                                                <p className=" text-end date">
                                                    {item?.name + ", "}
                                                    {
                                                        formatDate(item?.timestamp)
                                                    }
                                                </p>
                                                <p className="text">
                                                    {item?.note}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-response-container position-relative">
                    <div className="user-respose-wrapper internal-scroll">
                        <div className="user-detail-header user-response-header">
                            <div className='header coach-header'>
                                <div className="left-box">
                                    <p>
                                        Question History
                                    </p>
                                    {/* <p className="text-wrapper-1">
                                        Responses Table
                                    </p> */}
                                </div>
                                <div className="right-box">
                                    {
                                        tabId!=2?
                                        <button
                                        className={`add-coach-button `}
                                        onClick={() => setShowAssignQuestionModal(!showAssignQuestinModal)}
                                    >
                                        <img src={assignQuestion} alt="add user" />
                                        <p>
                                            Quiz Questions
                                        </p>
                                    </button>
                                        :
                                        ''
                                    }
                                   
                                </div>
                            </div>

                            <div className="tabHeader">
                                <div onClick={() => changeTab(1)} className={`paidTab ${tabId == 1 ? 'active' : ''}`}>Status</div>
                                <div onClick={() => changeTab(2)} className={`paidTab ${tabId == 2 ? 'active' : ''}`}>Quiz</div>
                            </div>
                        </div>
                        {
                            // (dateList.length > 0 && tabId===1 &&
                            //     dateList !== undefined &&
                            //     dateList !== null) ?

                            //     :


                            (tabId === 1 ?
                                dateList.length > 0 && dateList !== undefined && dateList !== null ?
                                    <div className="user-questionnair-history-wrapper">
                                        <div className="date-timelist-wrapper">
                                            {
                                                (dateList.length > 0 &&
                                                    dateList != undefined &&
                                                    dateList != null) &&
                                                dateList?.map((item, index) => {
                                                    return (
                                                        <p key={index} className={`date-time-item ${timeSlot === item.dates ? 'date-time-item-active' : ''} `} onClick={() => handleGetResponses(item?.dates)}>
                                                            {formateDateTime(item?.dateTime)}
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            {(currentQuestionnaireData.length > 0 &&
                                                currentQuestionnaireData != undefined &&
                                                currentQuestionnaireData != null) &&
                                                (
                                                    currentQuestionnaireData?.map((item, index) => {
                                                        return (
                                                            <div className="question-container-wrapper" key={index}>
                                                                <div className="question-title-wrapper"
                                                                    onClick={() => setQuestionDetailModal({ status: !questionDetailModal.status, id: item?.questionId })}
                                                                >
                                                                    <p className="question-title">
                                                                        Question {index + 1}
                                                                    </p>
                                                                    {questionDetailModal.status === true ?
                                                                        <img src={arrow} alt="left-arrow" className=' left-arrow upWard' />
                                                                        :
                                                                        <img src={arrow} alt="left-arrow " className=' left-arrow downWard' />
                                                                    }
                                                                </div>
                                                                {(questionDetailModal.status == true && questionDetailModal.id === item?.questionId) &&
                                                                    <div className="question-deatil-wrapper">
                                                                        <p className=" text extra-comment">
                                                                            {item?.question}
                                                                        </p>
                                                                        <p className="response">
                                                                            Response:
                                                                            {
                                                                                item?.responses[0]?.paidSelection.map((optionItem, index) => {
                                                                                    return (
                                                                                        <span key={index}>{" " + optionItem +
                                                                                            ","}</span>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </p>
                                                                        <div className="client-note-wrapper">
                                                                            <p className="text-wrapper-1">
                                                                                Client Note
                                                                            </p>
                                                                            <p className="date">
                                                                                {formateDateTime(item?.responses[0]?.responceTime)}

                                                                            </p>
                                                                        </div>
                                                                        <p className="text extra-comment">
                                                                            {item?.responses[0]?.additionalNotes}
                                                                        </p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                )
                                            }
                                            {
                                                currentQueLoading &&
                                                <BeatComponentOverlayLoader />
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-center">
                                        No Data Found
                                    </div>

                                :
                                freeQuestionList.length>9 && freeQuestionList!==undefined && freeQuestionList!==null?
                                freeQuestionList?.map((item,index)=>{
                                    return (
                                        <div className="question-container-wrapper" key={index}>
                                            <div className="question-title-wrapper"
                                                onClick={() => setFreeQuestionDetailModal({ status: !freeQuestionDetailMoal.status, id: item?.questionId })}
                                            >
                                                <p className="question-title">
                                                    Question {index + 1}
                                                </p>
                                                {freeQuestionDetailMoal.status === true ?
                                                    <img src={arrow2} alt="left-arrow" className=' left-arrow upWard' />
                                                    :
                                                    <img src={arrow2} alt="left-arrow " className=' left-arrow downWard' />
                                                }
                                            </div>
                                            {(freeQuestionDetailMoal.status == true && freeQuestionDetailMoal.id === item?.questionId) &&
                                                <div className="question-deatil-wrapper">
                                                    <p className=" text extra-comment">
                                                        {item?.question}
                                                    </p>
                                                    <p className="response">
                                                        Response:
                                                        {
                                                            item?.responses[0]?.paidSelection.map((optionItem, index) => {
                                                                return (
                                                                    <span key={index}>{" " + optionItem +
                                                                        ","}</span>
                                                                )
                                                            })
                                                        }
                                                    </p>
                                                    <div className="client-note-wrapper">
                                                        <p className="text-wrapper-1">
                                                            Client Note
                                                        </p>
                                                        <p className="date">
                                                            {formateDateTime(item?.responses[0]?.responceTime)}

                                                        </p>
                                                    </div>
                                                    <p className="text extra-comment">
                                                        {item?.responses[0]?.additionalNotes}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <div className="d-flex justify-content-center">
                                        No Data Found
                                    </div>

                            )
                        }
                    </div>
                </div>

            </div>
            {showAssignQuestinModal &&
                <AssignQuestionModal
                    show={showAssignQuestinModal}
                    onHide={() => setShowAssignQuestionModal(false)}
                    triggered={triggered}
                    setTriggered={setTriggered}
                    clientId={clientId}
                    coachId={userData?.user?.coachId}
                    assignedQuestionList={clientDetail[0].questions}
                    setClientDetail={setClientDetail}
                />
            }
            {
                showFeedbackModal &&
                <AddCoachFeedBackModal
                    show={showFeedbackModal}
                    onHide={() => setShowFeedbackModal(false)}
                    triggered={triggered}
                    setTriggered={setTriggered}
                    clientId={clientId}
                    coachId={userData?.user?.coachId}
                />
            }
            {userIntroductionModalStatus &&
                <AddClientIntroductionModal
                    show={userIntroductionModalStatus}
                    onHide={() => setUserIntorductionModalStatus(false)}
                    clientDetail={clientDetail[0]?.introduction}
                    clientId={clientId}
                    coachId={userData?.user?.coachId}
                    setClientDetail={setClientDetail}
                />
            }
            {
                <AddClinetInfoCommonModal
                    show={showCommonModal}
                    onHide={() => setshowCommonModal(false)}
                    category={category}
                    // clientDetail={clientDetail[0]?.introduction}
                    clientId={clientId}
                    coachId={userData?.user?.coachId}
                    setClientDetail={setClientDetail}
                />
            }
            {
                <ClientDetailModal
                    show={userDetailModalStatus}
                    onHide={() => setUserDetailModalStatus(false)}
                    clientDetail={clientDetail[0]}
                />
            }
            {
                loading &&
                <BeatOverlayLoader />
            }
        </>

    );
};
