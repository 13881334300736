import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../BaseModal.css'
import './DeleteModal.css'
import { formateDateTime } from '../../../utils/Utils';
import { SimpleButton } from '../../atoms/SimpleButton';


export const DeleteModal = ({
    show,
    onHide,
    title,
    message,
    handleDelete
}) => {

    return (
        <Modal
            show={show}
            onHide={onHide}
            // size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="delete-wrapper">
                    <p>
                        {message}
                    </p>
                    <div className='button-wrapper'>
                        <SimpleButton
                            buttonText="Cancel"
                            buttonColor="but cancelButton"
                            width="30%"
                            handleButton={() => onHide()}
                            textColor="#F4FFF3"
                        />

                        <SimpleButton
                            buttonText="Delete"
                            buttonColor="but deleteBtn"
                            width="30%"
                            handleButton={() => handleDelete()}
                            textColor="#F4FFF3"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
            </Modal.Footer>
        </Modal>
    )
}