import React from 'react';
import Modal from 'react-bootstrap/Modal';
import '../BaseModal.css'
import '../deleteModal/DeleteModal.css'
import { SimpleButton } from '../../atoms/SimpleButton';


export const ConfirmationModal = ({
    show,
    onHide,
    title,
    message,
    handleFunction,
    firstButtonText,
    secondButtonText,
}) => {

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="delete-wrapper">
                    <p>
                        {message}
                    </p>
                    <div className='button-wrapper'>
                        <SimpleButton
                            buttonText={firstButtonText}
                            buttonColor={title==='Accept'?'but deleteBtn':'but cancelButton'}
                            width="30%"
                            handleButton={() => onHide()}
                            textColor="#F4FFF3"
                        />
                        <SimpleButton
                            buttonText={secondButtonText}
                            buttonColor={title==='Reject'?'but deleteBtn':'but cancelButton'}
                            width="30%"
                            handleButton={() => handleFunction()}
                            textColor="#F4FFF3"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
            </Modal.Footer>
        </Modal>
    )
}