import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import '../../../src/styles/GlobalStyles.css'
import image404 from '../../assets/images/pagenotfound.png'


const PageNotFound = () => {

  const navigate = useNavigate();

  useEffect(() => {
    // Use replace to update the history without creating a new entry
    const replace404 = () => {
      window.history.replaceState({}, document.title, '/');
    };

    // Call replace404 when the component mounts
    replace404();

    // Add a popstate event listener to handle the back button
    const handlePopstate = () => {
      replace404();
      // navigate('/app/manage-clients');
    };

    window.addEventListener('popstate', handlePopstate);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [navigate]);

  return (
    <div className="dashboardContent error-template">
      <div className='row'>
        <div className=" col-12 col-sm-9 col-xxl-6 p-0 region region-content ">
          <div className="">
            <img src={image404} alt="bar-1" />
            <h1 className="title page-title">Page not found</h1>
          </div>
          <p className="content">
            The requested page could not be found.
          </p>
          <p className="content continue-program-text" onClick={() => navigate(-1, { replace: true })}>
            Please click here to continue the program!
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
