export const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: state.isFocused
            ? "1px solid #7525E8 !important"
            : "1px solid #cccccc !important",
        // This line disable the blue border
        boxShadow: "none",
        background: state.isFocused ? "#ffffff" : "#ECF1FE !important",
        fontFamily: "Inter-SemiBold"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#7525E8"
                    : isFocused
                        ? "#976ed1"
                        : undefined,
            color: isDisabled
                ? undefined
                : isSelected
                    ? "#ffffff"
                    : isFocused
                        ? "#FFFFFF"
                        : '#323232',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "#7525E8"
                        : "#976ed1"
                    : undefined,
            },
        };
    },
};
export const addQuestionSelectInput = {
    control: (base, state) => ({
        ...base,
        border:'none',
        borderRadius:'15px',
        backgroundColor:'#F4F7FE',
        fontSize:'12px',
        padding:'8px 10px',
        // This line disable the blue border
        boxShadow: "none",
         
        fontFamily: "Inter-SemiBold"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            fontSize:'12px',
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#7525E8"
                    : isFocused
                        ? "#976ed1"
                        : undefined,
            color: isDisabled
                ? undefined
                : isSelected
                    ? "#ffffff"
                    : isFocused
                        ? "#FFFFFF"
                        : '#323232',
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "#7525E8"
                        : "#976ed1"
                    : undefined,
            },
        };
    },
};

