import React, { useState } from 'react'
import '../../styles/GlobalStyles.css'
import { IoIosNotificationsOutline } from "react-icons/io"
import { BsPersonFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { logOut } from '../../services/auth/AuthServices';
import { formatNumber } from '../../utils/Utils';
import './Header.css'


export const Header = ({ currentLocation, setHeaderTitle, setActiveLink, notificationCount }) => {
    const [show, setShow] = useState(false);
    const toggleShowA = () => setShow(!show);

    const navigate = useNavigate();

    //Hooks*******************************

    const handleResetPassword = () => {
        setActiveLink("")
        setHeaderTitle("Reset Password")
        navigate("/app/reset-password")
    }

    const handleNotificationPress = () => {
        setActiveLink("")
        setHeaderTitle("Notifications")
        navigate("/app/manage-notification")
    }

    const logout = () => {
        // localStorage.setItem("userState", "false");
        logOut();
        if (localStorage.getItem("userState") !== "true") {
            navigate("/");
        }
    }


    return (
        <>
            <div className="header">
                <div className="left-box" >
                    <p>
                        Pages / {currentLocation}
                    </p>
                    <p>
                        {currentLocation}
                    </p>
                </div>
                <div className="right-box ">
                    {currentLocation !== "Manage Notifications" &&
                        <div className="icon-container notifi-icon" onClick={handleNotificationPress}>
                            <IoIosNotificationsOutline style={{ color: '#7525E8', width: '24px', height: '24px' }} />
                            <div className='notifi-count' style={{
                                position: 'absolute',
                                color: '#7525E8',
                                top: -16,
                                right: -7,
                                borderRadius: 10,
                                background: '#FFE2E5',
                            }} >{formatNumber(notificationCount)}</div>
                        </div>
                    }
                    <div className="icon-container profile-icon" onClick={toggleShowA}>
                        <BsPersonFill style={{ color: '#7525E8', width: '24px', height: '24px' }} />
                        {show &&
                            <div className="profile-toast">
                                <p onClick={handleResetPassword}>
                                    Reset Password
                                </p>
                                <p onClick={logout}>Logout</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}