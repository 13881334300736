import React from 'react';
import { Link } from "react-router-dom";
import './SideBar.css'
import logo from '../../assets/images/Logo.png'
import appInactive from '../../assets/images/mAppInactive.png'
import appActive from '../../assets/images/mAppActive.png'
import coachActive from '../../assets/images/coachActive.png'
import coachInactive from '../../assets/images/coachInactive.png'
import clientActive from '../../assets/images/clientActive.png'
import clientInactive from '../../assets/images/clientInactive.png'
import queActive from '../../assets/images/queActive.png'
import queInactive from '../../assets/images/queInactive.png'
import videoActive from '../../assets/images/videoActive.png'
import videoInactive from '../../assets/images/videoInactive.png'


export const SideBar = ({ setHeaderTitle, setActiveLink, activeLink, sidebarRef }) => {

    const handleOnPress = (link, headerText) => {
        setActiveLink(link)
        setHeaderTitle(headerText)
    }

    const userRole = localStorage.getItem('role')

    return (
        <div id="sidebar" className="first-container" ref={sidebarRef}>
            <div className="sidebar-logo-container">
                <img src={logo} alt="app logo" width="96px" height="96px" />
            </div>
            <nav>
                <ul>
                    {userRole === "333" &&
                        <>
                            <li>
                                <Link to={`manage-app`} onClick={() => handleOnPress("app", "Manage Application")}>
                                    <img src={activeLink === "app" ? appActive : appInactive} alt="manageAppIcon" />
                                    <p className={activeLink === "app" ? "active" : "inactive"} >Manage Application</p>
                                    <div className={`${activeLink === 'app' ? ' active-bar active' : ' active-bar inactive'}`}></div>
                                </Link>
                            </li>
                            <li>
                                <Link to={`manage-coach`} onClick={() => handleOnPress("coach", "Manage Coaches")} >
                                    <img src={activeLink === "coach" ? coachActive : coachInactive} alt="coachAppIcon" />
                                    <p className={activeLink === "coach" ? "active" : "inactive"}>Manage Coaches</p>
                                    <div className={`${activeLink === 'coach' ? ' active-bar active' : ' active-bar inactive'}`}></div>
                                </Link>
                            </li>
                        </>
                    }
                    <li>
                        <Link to={`manage-clients`} onClick={() => handleOnPress("client", "Manage Clients")}>
                            <img src={activeLink === "client" ? clientActive : clientInactive} alt="clientAppIcon" />
                            <p className={activeLink === "client" ? "active" : "inactive"}>Manage Clients</p>
                            <div className={`${activeLink === 'client' ? ' active-bar active' : ' active-bar inactive'}`}></div>
                        </Link>
                    </li>
                    <li>
                        <Link to={`manage-questions`} onClick={() => handleOnPress("que", "Manage Questions")}>
                            <img src={activeLink === "que" ? queActive : queInactive} alt="queAppIcon" />
                            <p className={activeLink === "que" ? "active" : "inactive"}>Manage Questions</p>
                            <div className={`${activeLink === 'que' ? ' active-bar active' : ' active-bar inactive'}`}></div>
                        </Link>
                    </li>
                    <li>
                        <Link to={`manage-videos`} onClick={() => handleOnPress("video", "Manage Videos")}>
                            <img src={activeLink === "video" ? videoActive : videoInactive} alt="queAppIcon" />
                            <p className={activeLink === "video" ? "active" : "inactive"}>Manage Videos</p>
                            <div className={`${activeLink === 'video' ? ' active-bar active' : ' active-bar inactive'}`}></div>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}