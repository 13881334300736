import React from 'react';
import './Loader.css'
import BeatLoader from "react-spinners/BeatLoader";

export const BeatOverlayLoader = () => {
    return (
        <div className="loader-container-wrapper loader-container">
            <BeatLoader color="#7525E8" />
        </div>
    )
}