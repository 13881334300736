import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../BaseModal.css'
import './AddQuestionModal.css'
import Select from 'react-select';
import BeatLoader from "react-spinners/BeatLoader";
// import { selectStyle } from '../../../styles/ReactSelectStyles';
import { selectStyle } from '../../../styles/ReactSelectAddQuestionStyle';
import { getHealthCategory } from '../../../services/common/CommonServices';
import { addNewQuestion, updateFreeQuizQuestion, updateQuestion } from '../../../services/admin/AdminServices';
import { toast } from 'react-toastify';
import { RxCross2 } from "react-icons/rx";


export const AddQuestionModal = ({ show, onHide, editableQuestion, isFreeQuiz, setEditableQuestion, triggered, setTriggered }) => {
    // console.log(editableQuestion, "its data for edit")
    let editDatalength = Object.keys(editableQuestion).length
    const [loader, setLoader] = useState(false)
    // const [triggered, setTriggered] = useState(false)
    const [deletedOptions, setDeletedOption] = useState([])
    const [questionData, setQuestionData] = useState(
        {
            id: '',
            question: '',
            type: '',
            treatmentCategory: { label: '', value: '' },
            selection: [],
        }
    )


    const [questionDataError, setQuestionDataError] = useState({
        questionError: '',
        typeError: '',
        treatmentCategoryError: '',
        optionsArrayError: '',
    })
    const [healthCategory, setHealthCategory] = useState([])
    const [errorModalMessage, setErroModalMessage] = useState()
    const [optionsArray, setOptionsArray] = useState(
        [
            { selectionId: 1, selection: '' },
            { selectionId: 2, selection: '' },
        ]
    )
    const typeArray =
        [
            {
                label: 'Single', value: 'Single'
            },
            {
                label: 'Multiple', value: 'Multiple'
            }
        ]
    /**********effects***********/

    useEffect(() => {
        handleHealthCategory();
    }, [])


    useEffect(() => {
        // console.log(questionData, "its data")
        // let editDatalength = Object.keys(editableQuestion).length
        if (editDatalength > 0) {
            const newarr = editableQuestion.options.map((item) => {
                return {
                    selectionId: item.selectionId, selection: item?.selection, new: 'no', changed: 'no', deleted: 'no'
                }
            })
            setQuestionData({
                id: editableQuestion.questionId,
                question: editableQuestion.question,
                type: editableQuestion.type,
                treatmentCategory: { label: editableQuestion.categoryName, value: editableQuestion.treatmentCategory },
                selection: [],
            })
            setOptionsArray(editableQuestion?.options ? newarr : [
                { selectionId: 1, selection: ''},
                { selectionId: 2, selection: ''  },
            ])
        }
        else {
            setQuestionData({
                id: '',
                question: '',
                type: '',
                treatmentCategory: { label: '', value: '' },
                selection: [],
            })
            setOptionsArray([
                { selectionId: 1, selection: '' },
                { selectionId: 2, selection: ''  },
            ])
        }
    }, [editableQuestion])


    /********* helpers******** */

    const validation = () => {
        let optionArray = optionsArray.filter((data => data.selection.trim() == ''))
        // console.log(optionArray)
        if (questionData.type === "") {
            setQuestionDataError({ ...questionDataError, typeError: "Question type can not be empty!" })
            return false;
        } else if (questionData.treatmentCategory.label === "" && questionData.treatmentCategory.value === "") {
            setQuestionDataError({ ...questionDataError, treatmentCategoryError: "Question category can not be empty!" })
            return false;
        } else if (questionData.question.trim() === "") {
            setQuestionDataError({ ...questionDataError, questionError: "Question can not be empty!" })
            return false;
        } else if (optionArray.length != 0) {
            setQuestionDataError({ ...questionDataError, optionsArrayError: "Options can not be empty!" })
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = (e) => {
        if (!isFreeQuiz) {
          let requestObject;
          let res = validation();
          // console.log(res, 'its res')
          if (res) {
            if (editDatalength > 0) {
              const deletedOption = deletedOptions.map((item) => {
                return item.selectionId;
              });
              // console.log(deletedOption, "its array")
              console.log("hello", optionsArray);
              const newOptions = optionsArray
                .filter((item) => item.new === "yes")
                .map((item) => ({ selectionValue: item?.selection }));
    
              console.log(newOptions, "new options");
    
              const getOptionAll = optionsArray
                .filter((item) => item.new === "no")
                .map((item) => ({ id: item?.selectionId, value: item?.selection }));
    
              console.log(getOptionAll, "no wale");
              requestObject = {
                questionId: questionData.id,
                question: questionData.question,
                type: questionData.type,
                treatmentCategory: questionData.treatmentCategory.value,
                selectionId: getOptionAll,
                newSelections: newOptions.length > 0 ? newOptions : [],
                deleteSelections: deletedOption.length > 0 ? deletedOption : [],
              };
              // console.log(requestObject, 'its object request')
            } else {
              const getOptionArray = optionsArray.map((item) => {
                return item?.selection;
              });
              requestObject = {
                question: questionData.question,
                type: questionData.type,
                treatmentCategory: questionData.treatmentCategory.value,
                selection: getOptionArray,
              };
            }
          }
    
          if (res) {
            console.log("RO: ", requestObject);
            setLoader(true);
            let response;
            if (editDatalength > 0) {
              response = updateQuestion(requestObject);
            } else {
              response = addNewQuestion(requestObject);
            }
            response
              .then((data) => {
                // console.log(data, 'after  responsd')
                setLoader(false);
                if (data.status === true) {
                  setQuestionData({
                    id: "",
                    question: "",
                    type: "",
                    treatmentCategory: { label: "", value: "" },
                    selection: [],
                  });
                  setOptionsArray([
                    { selectionId: 1, selection: "" },
                    { selectionId: 2, selection: "" },
                  ]);
                  onHide();
                  setEditableQuestion({});
                  setTriggered(!triggered);
                  if (editDatalength > 0) {
                    toast("Question updated successfully", {
                      type: "success",
                      autoClose: 2000,
                    });
                  } else {
                    toast("Question added successfully", {
                      type: "success",
                      autoClose: 2000,
                    });
                  }
                } else {
                  toast("Failed to add or update question!", {
                    type: "error",
                    autoClose: 3000,
                  });
                }
                // console.log(data, "its response while add a question succesfully")
              })
              .catch((error) => {
                setLoader(false);
                toast("Something went wrong, Please try again later!", {
                  type: "error",
                  autoClose: 3000,
                });
                // console.log(error, "its error while add a new question")
              });
          }
        } else {
          const deletedOption = deletedOptions.map((item) => {
            return item.selectionId;
          });
          // console.log(deletedOption, "its array")
          const newOptions = optionsArray
            .filter((item) => item.new === "yes")
            .map((item) => ({ selectionValue: item?.selection }));
    
          // console.log(newOptions, "new options")
    
          const getOptionAll = optionsArray
            .filter((item) => item.new === "no")
            .map((item) => ({ id: item?.selectionId, value: item?.selection }));
          let requestObject = {
            questionId: questionData.id,
            question: questionData.question,
            selectionId: getOptionAll,
            newSelections: newOptions.length > 0 ? newOptions : [],
            deleteSelections: deletedOption.length > 0 ? deletedOption : [],
          };
          setLoader(true);
          const response = updateFreeQuizQuestion(requestObject);
          response
            .then((result) => {
              setLoader(false);
              toast("Question updated successfully", {
                type: "success",
                autoClose: 2000,
              });
              onHide();
            })
            .catch((error) => {
              setLoader(false);
              toast("Something went wrong, Please try again later!", {
                type: "error",
                autoClose: 3000,
              });
              // console.log(error, "its error while add a new question")
            });
        }
      };

    const handleChange = (key, value) => {
        if (key === 'treatmentCategory') {
            setQuestionData({ ...questionData, [key]: { label: value.name, value: value.categoryCode } })
            setQuestionDataError({ ...questionDataError, treatmentCategoryError: "" })
        } else {
            setQuestionData({ ...questionData, [key]: value })
            if (key === 'type') {
                setQuestionDataError({ ...questionDataError, typeError: "" })
            }
            if (key === 'question') {
                setQuestionDataError({ ...questionDataError, questionError: "" })
            }
        }
    }

    const handleChangeOption = (key, e) => {
        console.log(key, e)
        let newArray = optionsArray.map((item, index) => {
            console.log(item)
            setQuestionDataError({ ...questionDataError, optionsArrayError: "" })
            if (item.selectionId === key) {
                if (editDatalength > 0) {
                    return { ...item, selection: e, changed: 'yes' }
                }
                else {
                    return { ...item, selection: e }
                }
            }
            return item
        })
        console.log(newArray)
        setOptionsArray(newArray)
        // optionsArray.map((item) => {
        //     if (item.value.trim() === "") {
        //         setQuestionDataError({ ...questionDataError, optionsArrayError: "Options can not be empty!" })
        //         return false;
        //     }
        // })
    }

    const handleHealthCategory = () => {
        const response = getHealthCategory()
        response.then((data) => {
            if (data?.status === true) {
                setHealthCategory(data.data)
            }
            else {
                setErroModalMessage(data)
            }
            // console.log(data, "data to get all health category")
        })
            .catch((error) => {
                setErroModalMessage({ status: false, message: "Something went wrong! please try again later." })
                // console.log(error, "its error while health category")
            })
    }

    const handleAddMore = () => {
        let optionArrayLength = optionsArray.length
        if (editDatalength > 0) {
            setOptionsArray([...optionsArray, { selectionId: optionArrayLength + 1, selection: '', new: 'yes', changed: 'no', deleted: 'no' }])
        }
        else {
            setOptionsArray([...optionsArray, { selectionId: optionArrayLength + 1, selection: '' }])
        }
    }

    const removeOptions = (id) => {
        if (optionsArray.length > 2) {
            if (editDatalength > 0) {
                const delteObject = optionsArray.filter((item) => (item.selectionId === id && item.new === 'no'))
                setDeletedOption([...deletedOptions, ...delteObject])
            }
            const res = optionsArray.filter((item) => item.selectionId != id)
            setOptionsArray(res);
        }
    }
    const hanldeHide = () => {
        onHide()
        setEditableQuestion({})
        setDeletedOption([]);
    }

    return (
        <Modal show={show} onHide={() => hanldeHide()}
            // {...props}
            size="lg"
            className={isFreeQuiz ? 'customHeight' : ''}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {Object.keys(editableQuestion).length > 0 ?
                        'Edit Question' :
                        'Add New Question'
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='addQuestion-modal-container'>
                    <form>
                        <div className=''>
                            <div>
                                <Select
                                    required
                                    styles={selectStyle}
                                    placeholder={
                                        <div className='placeHolderStyle'>Question type*</div>
                                    }
                                    value={
                                        questionData.type === "" ? "" :
                                            { label: questionData.type, value: questionData.type }
                                    }
                                    // value={{ label: questionData.type, value: questionData.type }}
                                    options={typeArray}
                                    onChange={(e) => {
                                        handleChange("type", e.value);
                                    }}
                                />
                                {questionDataError.typeError &&
                                    <div className="error-message-style-container">
                                        <small className="text-danger input-label-style error-message-style">
                                            {" "}
                                            {questionDataError.typeError}
                                        </small>
                                    </div>
                                }
                            </div>
                            <div>
                                <Select
                                    required
                                    styles={selectStyle}
                                    // value={
                                    //     { label: questionData.type, value: questionData.type }
                                    // }
                                    placeholder={
                                        <div className='placeHolderStyle'>Health category*</div>
                                    }
                                    value={
                                        questionData.treatmentCategory?.value === "" ? "" :
                                            { label: questionData.treatmentCategory?.label, value: questionData.treatmentCategory?.value }
                                    }
                                    // value={{ label: questionData.treatmentCategory?.label, value: questionData.treatmentCategory?.value }}
                                    options={healthCategory}
                                    onChange={(e) => {
                                        handleChange("treatmentCategory", e);
                                    }}
                                />
                                {questionDataError.treatmentCategoryError &&
                                    <div className="error-message-style-container">
                                        <small className="text-danger input-label-style error-message-style">
                                            {" "}
                                            {questionDataError.treatmentCategoryError}
                                        </small>
                                    </div>
                                }
                            </div>
                        </div>
                        <div>
                            <input
                                required
                                autoComplete="false"
                                type='text'
                                value={questionData?.question}
                                className="form-control form-control-icon-space placeholder-style"
                                // id="question"
                                placeholder='Enter new question*'
                                onChange={(e) => handleChange('question', e.target.value)}
                            />
                            {questionDataError.questionError &&
                                <div className="error-message-style-container">
                                    <small className="text-danger input-label-style error-message-style">
                                        {" "}
                                        {questionDataError.questionError}
                                    </small>
                                </div>
                            }
                        </div>
                        <div className='options-container'>
                            {
                                optionsArray.map((item, index) => {
                                    return (
                                        <div className='options' key={item?.selectionId}>
                                            <input
                                                required
                                                autoComplete="false"
                                                type='text'
                                                value={item?.selection}
                                                className="form-control form-control-icon-space placeholder-style"
                                                id={`${item?.selectionId}`}
                                                placeholder='Type option*'
                                                onChange={(e) => handleChangeOption(item?.selectionId, e.target.value)}
                                            />
                                            {optionsArray.length > 2 &&
                                                <div onClick={() => removeOptions(item?.selectionId)}>
                                                    <RxCross2 style={{ color: '#F64E60', width: '15px', height: '15px' }} />
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <div className='deleteButton' onClick={handleAddMore}>
                                + Add More
                            </div>
                            {questionDataError.optionsArrayError &&
                                <div className="error-message-style-container">
                                    <small className="text-danger input-label-style error-message-style">
                                        {" "}
                                        {questionDataError.optionsArrayError}
                                    </small>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    type='submit'
                    disabled={loader}
                    onClick={() => handleSubmit()}
                >
                    {loader ?
                        <BeatLoader color="#7525E8" /> :
                        Object.keys(editableQuestion).length > 0 ?
                            'UPDATE' :
                            'SUBMIT'

                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}