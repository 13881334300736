import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../BaseModal.css'
import './AssignQuestionModal.css'
import Select from 'react-select';
import BeatLoader from "react-spinners/BeatLoader";
import { selectStyle } from '../../../styles/ReactSelectAddQuestionStyle';
import { assignQuestions, deleteAssignedQuestionArray, fetchAllQuestions } from '../../../services/common/CommonServices';
import { toast } from 'react-toastify';
import { RxCross2 } from "react-icons/rx";
import { somethingWrongText } from '../../../constants/Variables';
import { DeleteModal } from '../deleteModal/DeleteModal';


export const AssignQuestionModal = ({
    show,
    onHide,
    triggered,
    setTriggered,
    clientId,
    coachId,
    assignedQuestionList
}) => {

    const [filedError, setFieldError] = useState("")
    const [loader, setLoader] = useState(false);
    const [allQuestinList, setAllQuestionList] = useState([]);
    const [seletedQuestions, setSelectedQuestions] = useState([]);
    const [selectForDelete, setSelectForDelete] = useState([])
    const [isNewQuestion, setIsNewQuestion] = useState([]);
    const [deleteModalShow, setDeleteModalShow] = useState(false)

    /**********effects***********/


    useEffect(() => {
        handleFetchAllQuestions();
    }, [])


    useEffect(() => {
        const newArr = assignedQuestionList?.map((item) => {
            return {
                id: item?.questionId,
                label: item?.question,
                value: item?.questionId,
                new: false,
                selected: false,
            }
        })
        setSelectedQuestions(newArr);
    }, [assignedQuestionList])

    /*****************helpers******* */

    const handleFetchAllQuestions = () => {
        const response = fetchAllQuestions("paid");
        response.then((data) => {
            if (data?.status === true) {
                const newArr = data?.data?.map((item) => {
                    return {
                        id: item?.questionId,
                        label: item?.question,
                        value: item?.questionId,
                        new: true,
                    }
                })
                setAllQuestionList(newArr.reverse())
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast("Failed to load question list!", { type: 'error' });
            })
    }

    const handleChange = (e) => {
        let newAssigned = e.pop();
        setIsNewQuestion([...isNewQuestion, newAssigned?.id])
        setSelectedQuestions([...seletedQuestions, { ...newAssigned, selected: false }])
        setFieldError("")
    }

    const hanldeHide = () => {
        onHide()
    }

    const handleRemoveOptions = (item) => {
        const filterdArray = seletedQuestions.filter((questionItem) => questionItem?.id !== item?.id)
        setSelectedQuestions(filterdArray);
        const filteredNewQuestionArray = isNewQuestion.filter((itemId) => itemId !== item?.id)
        setIsNewQuestion(filteredNewQuestionArray)
    }

    const handleDelete = () => {
        setLoader(true)
        const requestObject = {
            userid: clientId,
            questionid: selectForDelete
        }
        setDeleteModalShow(false)
        const deleteResponse = deleteAssignedQuestionArray(requestObject);
        deleteResponse.then((data) => {
            setLoader(false)
            if (data.status) {
                toast(data?.message, { type: 'success' });
                setTriggered(!triggered)
                onHide();
            }
            else {
                setLoader(false)
                toast(data?.message, { type: 'error' });
            }
        }).catch((error) => {
            setLoader(false)
            toast(somethingWrongText, { type: 'error' });
        })
    }

    const selectDeselectQuestion = (data) => {
        const getItem = seletedQuestions.map((item) => {
            if (item?.id == data?.id) {
                return { ...item, selected: !item?.selected }
            }
            return item
        })
        setSelectedQuestions(getItem)
        let isContain = selectForDelete.some((item) => item === data?.id)
        console.log(isContain)
        if (isContain) {
            const arr = selectForDelete.filter((filterItem) => filterItem !== data?.id)
            setSelectForDelete(arr)
        }
        else {
            setSelectForDelete([...selectForDelete, data?.id])
        }
    }

    const handleSubmit = () => {
        if (isNewQuestion.length === 0) {
            setFieldError("Please select atleast one question!")
            return false;
        }

        const requestObject = {
            clientId: clientId,
            coachId: coachId,
            questionId: seletedQuestions.map((item) => item?.id)
        }
        setLoader(true)
        const questionAssignResponse = assignQuestions(requestObject)
        questionAssignResponse.then((data) => {
            setLoader(false)
            if (data.status) {
                toast(data?.message, { type: 'success' });
                setSelectedQuestions([]);
                setTriggered(!triggered)
                onHide();
            }
            else {
                toast(data?.message, { type: 'error' });
                setTriggered(!triggered)
                setSelectedQuestions([]);
                onHide();
            }
        })
            .catch((error) => {
                setLoader(false)
                console.log(error?.response?.statusText, 'Something went wrong!')
                toast(error?.response?.statusText || "Something went wrong, please try again!", { type: 'error' });

            })
    }



    return (
        <>
            <Modal show={show} onHide={() => hanldeHide()}
                className='assign-coach-modal'
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                        Assign Questions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='addQuestion-modal-container assignQuestion-modal-container'>
                        <form>
                            <div>
                                <Select
                                    required
                                    isMulti
                                    closeMenuOnSelect={false}
                                    styles={selectStyle}
                                    placeholder={
                                        <div className='placeHolderStyle'>Select questions*</div>
                                    }
                                    value={
                                        seletedQuestions.length <= 0 ? "" :
                                            (seletedQuestions.map((item) => {
                                                return (
                                                    { label: item.label, value: item.value, id: item.id }
                                                )
                                            })
                                            )
                                    }
                                    // value={{ label: questionData.type, value: questionData.type }}
                                    controlShouldRenderValue={false}
                                    options={allQuestinList}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    isDisabled={selectForDelete.length > 0 ? true : false}
                                />
                            </div>

                            {seletedQuestions.length > 0 &&
                                <div className='selected-question-wrapper internal-scroll'>
                                    {
                                        seletedQuestions.map((item) => {
                                            return (
                                                <div className='render-selected-question-wrapper' key={item?.id}>
                                                    {isNewQuestion.length === 0 &&
                                                        <div className={
                                                            item?.selected === true ? 'selected-Item' : 'unselected-item'} onClick={() => selectDeselectQuestion(item)}></div>
                                                    }
                                                    <p>
                                                        {item?.label}{item?.new}
                                                    </p>
                                                    {item?.new &&
                                                        <div onClick={() => handleRemoveOptions(item)} className='question-cross-icon'>
                                                            <RxCross2 style={{ color: '#F64E60', width: '15px', height: '15px' }} />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                            {filedError &&
                                <div className="error-message-style-container mt-0 pt-0">
                                    <small className="text-danger input-label-style error-message-style ">
                                        {" "}
                                        {filedError}
                                    </small>
                                </div>
                            }
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    {selectForDelete.length > 0 ?
                        <Button
                            type='delete'
                            disabled={loader}
                            onClick={() =>
                                setDeleteModalShow(true)
                            }
                        >
                            {loader ?
                                <BeatLoader color="#7525E8" /> :
                                "DELETE"
                            }</Button>
                        :
                        <Button
                            type='submit'
                            disabled={loader}
                            onClick={() => handleSubmit()}
                        >
                            {loader ?
                                <BeatLoader color="#7525E8" /> :
                                "SUBMIT"
                            }</Button>
                    }
                </Modal.Footer>
                {/* {
                deleteLoading &&
                <BeatComponentOverlayLoader />
            } */}

            </Modal>
            <DeleteModal
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                handleDelete={handleDelete}
                title="Delete Questions"
                message="Are you sure, do you want to delete questions?"
            />
        </>
    )
}