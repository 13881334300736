import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../../../styles/GlobalStyles.css'
import '../BaseModal.css'
 
import { TextInputField } from '../../atoms/TextInputField';
import BeatLoader from "react-spinners/BeatLoader";
import { requiredText, somethingWrongText } from '../../../constants/Variables';
import { toast } from 'react-toastify';
import { addHealthCategory, addSubHealthCategroy, updateHealthCategory, updateHealthSubCategory } from '../../../services/common/CommonServices';



export const AddEditSubHealthCategory = ({
    show,
    onHide,
    triggered,
    editableData,
    setEditableSubHeathCategory,
}) => {
    // console.log(testimonialItemData, "its data")
    let isEdit=false;
    let size = Object.keys(editableData)
    console.log(size)
    if(size.includes('subCategorycode')){
        isEdit=true;
    }else{
        isEdit=false;
    }
    // console.log(size, 'its size')
    console.log(isEdit)
    console.log(editableData)
    const [values, setValues] = useState({
        code: isEdit?editableData?.subCategorycode:'',
        category: isEdit?editableData?.name:''
    })

    const [errorMessageObject, setErrorMessageObject] = useState({
        codeError: '',
        categoryError: '',
    })
    const [loading, setLoading] = useState(false);

    //helpers****************************************

    const hanldeInputValue = (key, e) => {
        setValues({ ...values, [key]: e })
    }

    const handleFocus = (key, e) => {
        key === "code" ? setErrorMessageObject({ ...errorMessageObject, codeError: '' }) : setErrorMessageObject({ ...errorMessageObject, categoryError: '' })
    }

    const handleBlur = () => {
    }

    const handleHide = () => {
        setEditableSubHeathCategory();
        onHide();
    }

    const validation = () => {
        if (values.code?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, codeError: requiredText })
            return false;
        }
        else if (values.category?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, categoryError: requiredText })
            return false;
        }
        else {
            return true;
        }
    }

    const handleSubmit = () => {
        if (validation()) {
            setLoading(true)
            let requestObject={};
            if(isEdit){

                  requestObject = {
                    subCategorycode: values.code,
                    subCategoryname: values.category,
                }
            }else{
                  requestObject={
                    "cateCode":editableData.categoryCode,
                    "subCatename":values.category,
                    "subCatcode":values.code
                    
                 }
            }
            const addCategoryResponse = isEdit ? updateHealthSubCategory(requestObject) : addSubHealthCategroy(requestObject);
            addCategoryResponse.then((data) => {
                setLoading(false)
                // console.log(data, 'its response after testimonial')
                if (data?.status) {
                    triggered();
                    toast(data?.message, { type: 'success' });
                    setValues({
                        cate_code: '',
                        cate_name: '',
                    })
                    handleHide();
                }
                else {
                    toast(data?.message, { type: 'error' });
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error, "error inthe submit testimonials")
                toast(somethingWrongText, { type: 'error' });
            })
        }
    }


    return (
        <Modal show={show} onHide={handleHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {isEdit ? "Edit" : "Add"}{" "} Sub Health Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextInputField
                        // label="Title/Reference"
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Category code*"
                        error={errorMessageObject.codeError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="code"
                        value={values.code}
                        style="modal-input-style"
                        disabled={isEdit ? true : false}
                    />
                    <TextInputField
                        // label="Title/Reference"
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Category name*"
                        error={errorMessageObject.categoryError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="category"
                        value={values.category}
                        style="modal-input-style"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                >
                    {loading ?
                        <BeatLoader color="#7525E8" /> :
                        isEdit ? "Update" : "Submit"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}