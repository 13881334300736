import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../BaseModal.css'
// import './AssignQuestionModal.css'
import './AddCoachFeedBackModal.css'
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from 'react-toastify';
import { addFeedBack } from '../../../services/common/CommonServices';


export const AddCoachFeedBackModal = ({
    show,
    onHide,
    triggered,
    setTriggered,
    clientId,
    coachId,
}) => {

    const [filedError, setFieldError] = useState("")
    const [feedbackText, setFeedbackText] = useState("")
    const [loader, setLoader] = useState(false);



    /**********effects***********/


    /*****************helpers******* */

    const handleChange = (e) => {
        // console.log(e.target.value)
        setFeedbackText(e.target.value)
        setFieldError("")
    }

    const hanldeHide = () => {
        onHide()
    }

    const handleSubmit = () => {
        if (feedbackText.trim() === "") {
            setFieldError("This field is required!")
            return false;
        }
        setLoader(true)
        const requestObject = {
            clientId: clientId,
            coach_id: coachId,
            note: feedbackText
        }
        // console.log(requestObject, 'itskhfj h hf')
        const questionAssignResponse = addFeedBack(requestObject)
        questionAssignResponse.then((data) => {
            setLoader(false)
            // console.log('its data', data)
            if (data.status) {
                toast(data?.message, { type: 'success', autoClose: 1000 });
                setFeedbackText("")
                setTriggered(!triggered)
                onHide();
            }
            else {
                toast(data?.message, { type: 'error', autoClose: 2000 });
            }
        })
            .catch((error) => {
                setLoader(false)
                console.log(error?.response?.statusText, 'Something ')
                toast(error?.response?.statusText || "Something went wrong, please try again!", { type: 'error' });

            })

    }


    return (
        <Modal show={show} onHide={() => hanldeHide()}
            // {...props}
            className='assign-coach-modal'
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    Add Feedback
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='addQuestion-modal-container assignQuestion-modal-container'>
                    <form>
                        <textarea
                            className='text-area text-area-coach'
                            id="w3review"
                            name="w3review"
                            rows="9"
                            // cols="100"
                            onChange={(e) => handleChange(e)}>

                        </textarea>
                        {filedError &&
                            <div className="error-message-style-container mt-0 pt-0">
                                <small className="text-danger input-label-style error-message-style ">
                                    {" "}
                                    {filedError}
                                </small>
                            </div>
                        }
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    type='submit'
                    disabled={loader}
                    onClick={handleSubmit}
                >
                    {loader ?
                        <BeatLoader color="#7525E8" /> :
                        "SUBMIT"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}