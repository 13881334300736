import React from 'react';
import './Loader.css'
import { TailSpin } from 'react-loader-spinner';
import BeatLoader from "react-spinners/BeatLoader";

export const BeatComponentOverlayLoader = () => {
    return (
        <div className="loader-container-wrapper loader-component-container">
            <BeatLoader color="#7043ad" />
        </div>
    )
}