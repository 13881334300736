import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../styles/GlobalStyles.css";
import { Header } from "../components/headers/Header";
import { SideBar } from "../components/sidebar/SideBar";
import { Footer } from "../components/footer/Footer";
import { RxHamburgerMenu } from "react-icons/rx";
import { getNotificationCount } from "../services/common/CommonServices";

export const Root = () => {
  const [headerTitle, setHeaderTitle] = useState("");
  const [activeLink, setActiveLink] = useState("app");
  const [sideBarStatus, setSidebarStatus] = useState(true);
  const [notificationCount, setNotificationCount] = useState("")


  /******** hooks******* */
  const sidebarRef = useRef(null);
  const hamburgerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    let screenWidth = window.screen.width;
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target)
      ) {
        if (sideBarStatus && screenWidth < 768) {
          setSidebarStatus(false);
        }
      }
    };
    // Add event listener when the onclick outside the component
    document.addEventListener("click", handleClickOutside);
    // Remove event listener when the onclick outside the component
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    switch (location?.pathname) {
      case "/app/manage-app":
        setActiveLink("app");
        setHeaderTitle("Manage Application");
        break;
      case "/app/manage-coach":
        setActiveLink("coach");
        setHeaderTitle("Manage Coaches");
        break;
      case "/app/manage-clients":
        setActiveLink("client");
        setHeaderTitle("Manage Clients");
        break;
      case "/app/manage-questions":
        setActiveLink("que");
        setHeaderTitle("Manage Questions");
        break;
      case "/app/reset-password":
        setHeaderTitle("Reset Password");
        break;
      case "/app/client-detail":
        setHeaderTitle("Client Detail");
        setActiveLink("client");
        break;
      case "/app/manage-videos":
        setHeaderTitle("Manage Videos");
        setActiveLink("video");
        break;
      case "/app/manage-notification":
        setHeaderTitle("Manage Notifications");
        setActiveLink("");
        break;
      default:
        setActiveLink("");
        setHeaderTitle("");
    }

    const userId = localStorage.getItem('userId')
    const token = localStorage.getItem('token')
    const requestObject = {
      userId: userId,
      token: token,
    }
    getNotificationCountData(requestObject)

  }, [location]);


  //helper functions*******************
  const getNotificationCountData = (requestObject) => {
    const responseData = getNotificationCount(requestObject)
    responseData.then(data => {
      if (data.status) {
        setNotificationCount(data?.Count === 0 ? " " : data?.Count)
      }
    })
      .catch(error => {
      })
  };

  return (
    <div className="main-container">
      <div className="main-sub-container">
        <div
          className={`first-container-wrap ${sideBarStatus && "showSidebar"}`}
        >
          {/* <div ref={sidebarRef}> */}
          <SideBar
            setHeaderTitle={setHeaderTitle}
            setActiveLink={setActiveLink}
            activeLink={activeLink}
            sidebarRef={sidebarRef}
          />
          {/* </div> */}
        </div>
        <div id="second-container-wrap" className="second-container">
          <Header
            currentLocation={headerTitle}
            setHeaderTitle={setHeaderTitle}
            setActiveLink={setActiveLink}
            notificationCount={notificationCount}
          />
          <div className={`main`}>
            <Outlet setHeaderTitle={setHeaderTitle} headerTitle={headerTitle} />
          </div>
          <Footer />
        </div>
        <div className="hamburger-menu" ref={hamburgerRef}>
          <RxHamburgerMenu onClick={() => setSidebarStatus(!sideBarStatus)} />
        </div>
      </div>
    </div>
  );
};
