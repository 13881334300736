import React from "react";
import '../../styles/GlobalStyles.css'
import { FaEyeSlash, FaRegEye } from "react-icons/fa";

export const TextInputField = ({ isRequired, label, fieldType, idfor, placeholder, error, handleChange, blurHandler, focusHandler, keyName, value, togglePasswordVisiblity, passwordShown, style, min, max, disabled }) => {
    // console.log(keyName, 'jkjl')
    return (
        <>
            <div className={`form-group forminut-passeye ${style}`}>
                {label &&
                    <label
                        htmlFor={idfor}
                        className="input-label-style"
                    >
                        {label}{isRequired && <span className="required-Astric">*</span>}
                    </label>
                }
                <input
                    autoComplete="true"
                    type={passwordShown && (keyName === 'password' || keyName === "currentPassword") ? "text" : fieldType}
                    className={`form-control ${keyName === 'password' || keyName === "currentPassword" ? 'form-control-icon-space' : ''}`}
                    id={idfor}
                    placeholder={placeholder}
                    onChange={(e) => handleChange(keyName, e.target.value)}
                    onBlur={(e) => blurHandler(keyName, e.target.value)}
                    onFocus={(e) => focusHandler(keyName, e.target.value)}
                    value={value}
                    minLength={min}
                    maxLength={max}
                    disabled={disabled}
                />
                {(keyName === "password" || keyName === "currentPassword") &&
                    <div
                        className="passShow-eye"
                        onClick={() => togglePasswordVisiblity(keyName)}
                    >
                        {passwordShown ?
                            <FaEyeSlash style={{ color: '#A3AED0', fontSize: '20px', marginTop: '13px', marginRight: '13px' }} /> :
                            <FaRegEye style={{ color: '#A3AED0', fontSize: '20px', marginTop: '13px', marginRight: '13px' }} />}
                    </div>
                }
                {error &&
                    <div className="error-message-style-container">

                        <small className="text-danger input-label-style error-message-style">
                            {" "}
                            {error}
                        </small>
                    </div>
                }
            </div>
        </>

    )
}