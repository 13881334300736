import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../../../styles/GlobalStyles.css'
import '../BaseModal.css'
import './AddTestimonialModal.css'
import { TextInputField } from '../../atoms/TextInputField';
import BeatLoader from "react-spinners/BeatLoader";
import { requiredText, somethingWrongText } from '../../../constants/Variables';
import { toast } from 'react-toastify';
import { addTestimonial, updateTestimonial } from '../../../services/common/CommonServices';



export const AddTestimonialModal = ({
    show,
    onHide,
    triggered,
    testimonialItemData,
    setTestimonialItemId,
}) => {
    let size = Object.keys(testimonialItemData).length;
    const [testimonialDataObject, setTestimonialDataObject] = useState({
        title: testimonialItemData?.title || '',
        description: testimonialItemData?.description || '',
        link: testimonialItemData?.link || '',
    })
    const [errorMessageObject, setErrorMessageObject] = useState({
        titleError: "",
        descriptionError: '',
        linkError: '',
    })
    const [loading, setLoading] = useState(false);

    //helpers****************************************

    const hanldeInputValue = (key, e) => {
        setTestimonialDataObject({ ...testimonialDataObject, [key]: e })
    }

    const handleFocus = () => {
        setErrorMessageObject({ ...errorMessageObject, titleError: '' })
    }

    const handleBlur = () => {
    }

    const handleHide = () => {
        setTestimonialItemId();
        onHide();
    }

    const validation = () => {
        if (testimonialDataObject.title?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, titleError: requiredText })
            return false;
        }
        else if (testimonialDataObject.description?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, descriptionError: requiredText })
            return false;
        }
        else if (testimonialDataObject.link?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, linkError: requiredText })
            return false;
        }
        else {
            return true;
        }
    }

    const handleSubmit = () => {
        if (validation()) {
            setLoading(true)
            const requestObject = size !== 0 ? {
                id: testimonialItemData?.appConfId,
                title: testimonialDataObject.title,
                description: testimonialDataObject.description,
                link: testimonialDataObject.link,
            } :
                {
                    title: testimonialDataObject.title,
                    description: testimonialDataObject.description,
                    link: testimonialDataObject.link,
                }
            const addTestResponse = size !== 0 ? updateTestimonial(requestObject) : addTestimonial(requestObject);
            addTestResponse.then((data) => {
                setLoading(false)
                // console.log(data, 'its response after testimonial')
                if (data?.status) {
                    triggered();
                    toast(data?.message, { type: 'success' });
                    setTestimonialDataObject(
                        {
                            titleError: "",
                            descriptionError: '',
                            linkError: '',
                        })
                    handleHide();
                }
                else {
                    toast(data?.message, { type: 'error' });
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error, "error inthe submit testimonials")
                toast(somethingWrongText, { type: 'error' });
            })
        }
    }


    return (
        <Modal show={show} onHide={handleHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {
                        size !== 0 ? "Edit Testimonial" : "Add Testimonial"
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Title/reference*"
                        error={errorMessageObject.titleError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="title"
                        value={testimonialDataObject.title}
                        style="modal-input-style "
                    />
                    <textarea
                        className='text-area text-area-test text-area-style'
                        // id="testDescription"
                        name="w3review"
                        rows="9"
                        onFocus={() => setErrorMessageObject({ ...errorMessageObject, descriptionError: '' })}
                        placeholder='Add testimonial description*'
                        value={testimonialDataObject.description}
                        onChange={(e) => {
                            hanldeInputValue("description", e.target.value)
                        }
                        }
                    >
                    </textarea>
                    {errorMessageObject.descriptionError &&
                        <div className="error-message-style-container pb-2">
                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject.descriptionError}
                            </small>
                        </div>
                    }
                    <textarea
                        className='text-area text-area-link text-area-style'
                        name="w3review"
                        rows="4"
                        placeholder='Add testimonial link*'
                        onFocus={() => setErrorMessageObject({ ...errorMessageObject, linkError: '' })}
                        value={testimonialDataObject.link}
                        onChange={(e) => hanldeInputValue("link", e.target.value)}>
                    </textarea>
                    {errorMessageObject.linkError &&
                        <div className="error-message-style-container pb-2">
                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject.linkError}
                            </small>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                >
                    {loading ?
                        <BeatLoader color="#7525E8" /> :
                        size !== 0 ? "Update" : "Submit"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}