import React, { useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { useNavigate, useLocation } from 'react-router-dom'
import { SimpleButton } from '../../../components/atoms/SimpleButton'
import { CoachTabelHead } from '../../../components/headers/caochTableHead/CoachTableHead'
import { deleteUser, fetchAllCoach, updateRequestStatus } from '../../../services/admin/AdminServices'
import { NoDataFound } from '../../../components/atoms/NoDataFound'
import { BeatOverlayLoader } from '../../../components/loaders/BeatOverlayLoader'
import { Pagination } from '../../../components/modals/pagination/Pagniation'
import delteButton from '../../../assets/images/Delete.png'
import { activeInactiveClient, assignCoach, assignHelthCatgory, assignsubCategory, fetchAllClients, fetchClientByCoach, getHealthCategory } from '../../../services/common/CommonServices'
import Select from 'react-select';
import { selectStyle } from '../../../styles/ReactSelectStyles'
import arrow from '../../../assets/images/Icon.svg'
import { toast } from 'react-toastify';
import { somethingWrongText } from '../../../constants/Variables'
import { DeleteModal } from '../../../components/modals/deleteModal/DeleteModal'
import { ConfirmationModal } from '../../../components/modals/confirmationModal/ConfirmationModal'
 

export const ManageClient = () => {
    const [clientsList, setClientsList] = useState([])
    const [healthCategory, setHealthCategory] = useState([])
    const [nonManipulatedHealthCategoryData, setNonManipuldatedHeadlCategoryData] = useState([]);
    const [coachList, setCoachList] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDelteModalShow] = useState(false);
    const [acceptRejectModal, setAcceptRejectModal] = useState(false);
    const [acceptRejectUserInfo, setAcceptRejectUserInfo] = useState({})
    const [delteUserId, setDeleteUserId] = useState('')
    const [stausModal, setStatusModal] = useState(false);
    const [requestStatusModal, setRequestStatusModal] = useState({ status: false, id: '' })
    const [fetDataLoader, setFetchDataLoader] = useState(false);
    const [triggered, setTriggered] = useState(false)
    const [locationParams, setLocationParams] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 7;

    const navigate = useNavigate();
    let location = useLocation();
    const userRole = localStorage.getItem('role')
    const userId = localStorage.getItem('userId')
    // console.log(location?.state?.coachId, "its parsms")

    /*****useEffect**************/
    useEffect(() => {
        setLocationParams(location?.state?.coachId)
        setFetchDataLoader(true)
        handleHealthCategory();
        handleFetchData();
        handleCoachList();
    }, [location?.state?.coachId])

    // useEffect(()=>
    // {
    //     if(healthCategory) handleFetchData();
    // },[healthCategory])

    useEffect(() => {
        handleFetchData();
    }, [triggered])


    /***********pagination**********/

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = clientsList.slice(indexOfFirstRecord,
        indexOfLastRecord);
    const nPages = Math.ceil(clientsList.length / recordsPerPage)


    /*****Helper funcionts********/

    const handleFetchData = (status) => {
        let response;
        if ((location?.state?.coachId !== null && location?.state?.coachId !== undefined) || (userRole === "222")) {
            const requestObject = {
                coachId: location?.state?.coachId || userId,
                status: status
            }
            response = fetchClientByCoach(requestObject);
        }
        else {
            response = fetchAllClients(status);
        }

        response.then((data) => {
            // console.log("data", data)
            setFetchDataLoader(false)
            if (data.status === true) {

                setClientsList(data?.data)
            }

            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch(error => {
                setFetchDataLoader(false)
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleActiveInactive = (id, status) => {
        const requestObject = {
            Id: id,
            status: status === "Active" ? "Inactive" : "Active",
            role: '111'
        }
        const response = activeInactiveClient(requestObject)
        response.then((data) => {
            if (data?.status === true) {
                setTriggered(!triggered)
                toast(data?.message, { type: 'success' });
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        }).catch((error) => {
            toast(somethingWrongText, { type: 'error' });
        })
    }

    const handleDelete = () => {
        const requestObject = {
            userId: delteUserId,
            role: "111",
        }
        setDeleteUserId('')
        setDelteModalShow(false);
        const response = deleteUser(requestObject)
        response.then((data) => {
            if (data?.status === true) {
                toast(data?.message, { type: 'success' });
                setTriggered(!triggered)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleDeleteModal = (e, id) => {
        e.stopPropagation();
        setDeleteUserId(id)
        setDelteModalShow(true)
    }

    const handleHealthCategory = () => {
        const response = getHealthCategory()
        response.then((data) => {
            if (data?.status === true) {
                let newArray = []
                setNonManipuldatedHeadlCategoryData(data.data)
                data?.data.forEach((element) => {
                    let obj = {
                        label: element.value,
                        value: element.value,
                        categoryCode: element.categoryCode
                    }
                    newArray.push(obj);
                })
                setHealthCategory(newArray)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const getSubCategoryList = (data) => {
        let selectedCategoryCodeList = data.HealthCategories?.map((data) => { return data.categoryCode });
        let listOfSubCategory = [].concat(...(nonManipulatedHealthCategoryData.filter((data => selectedCategoryCodeList?.includes(data.categoryCode))).map((data => data.subCategory))))
        let finalArray = listOfSubCategory?.map((element) => {

            return {
                categoryCode: element.subCategorycode,
                value: element.name,
                label: element.name
            }
        })
        return finalArray
    }
    const selectHandler = (e, clientId, index) => {

        let arr = e?.map((element) => ({
            'categoryCode': element.categoryCode,
            'name': element.value
        }))
        let payloadCode = e?.map((element) => {
            return element.categoryCode
        })
        let isRemoving = false;
        let d = []
        let subCategoryList = []
        let subCategory = []
        if (clientsList[index].HealthCategories?.length >= arr?.length) {
            isRemoving = true;
            let previousCodeList = arr?.map((data) => { return data.categoryCode });
            //extracting the element which is removed
            debugger
            let elementThatIsRemoved = clientsList[index].HealthCategories.filter((data => !previousCodeList?.includes(data.categoryCode)));
            //fetch all the subcategory element from the healthCategory list
            let data = nonManipulatedHealthCategoryData.filter((data => data.categoryCode === elementThatIsRemoved[0].categoryCode))
            subCategory = data[0].subCategory?.map((data) => { return data.subCategorycode })
            //getting the subCategory list
            subCategoryList = clientsList[index].subCategories?.map((element) => { return element.subCategorycode });

            d = subCategoryList.filter((data => !subCategory?.includes(data)))

        }



        const requestObject = {
            categories: payloadCode,
            userId: clientId
        }
        // setTriggered(triggered)
        if (payloadCode?.length == 0) {
            toast.error('Atleast one health Category is required')
        } else if (d?.length == 0 && isRemoving) {
            toast.error(`Can't remove this element`)
        } else {
            const response = assignHelthCatgory(requestObject)
            response.then((data) => {
                if (data?.status === true) {
                    setClientsList(prevUsers => {
                        const updatedUsers = [...prevUsers];
                        updatedUsers[index].HealthCategories = arr;
                        return updatedUsers;
                    })

                    if (clientsList[0].HealthCategories?.length >= arr?.length) {

                        //creating a new array of selected subCategory list
                        let newArray = clientsList[index].subCategories.filter((data => !subCategory?.includes(data.subCategorycode)))
                        let newSubCategoryArray = newArray?.map((data) => { return data.subCategorycode })
                        const requestObject = {
                            subCategory: newSubCategoryArray,
                            userId: clientId
                        }
                        saveSubCategory(requestObject, index, newArray)
                    }
                }
                else {
                    toast(data?.message, { type: 'error' });
                }
            })
                .catch((error) => {
                    toast(somethingWrongText, { type: 'error' });
                })
        }

    }
    const subCategoryHandle = (e, clientId, index) => {
        console.log(e)
        let arr = e?.map((element) => ({
            'subCategorycode': element.categoryCode,
            'name': element.value
        }))
        let payloadCode = e?.map((element) => {
            return element.categoryCode
        })
        const requestObject = {
            subCategory: payloadCode,
            userId: clientId
        }
        if (payloadCode?.length == 0) {
            toast.error("Atleast one Sub health Category is requried");
        } else {
            saveSubCategory(requestObject, index, arr);
        }
    }
    const saveSubCategory = (requestObject, index, arr) => {
        const response = assignsubCategory(requestObject);
        response.then((data) => {
            if (data?.status === true) {
                toast(data?.message, { type: 'success' });
                setClientsList(prevUsers => {
                    const updatedUsers = [...prevUsers];
                    updatedUsers[index].subCategories = arr;
                    return updatedUsers;
                })
            }
        }).catch((error) => {

        })
    }
    const handleAssignCoach = (e, clientId) => {
        const requestObject = {
            userId: clientId,
            coach_id: [e],
        }
        const response = assignCoach(requestObject)
        response.then((data) => {
            // console.log(data, "its data when assigning coach")
            if (data?.status === true) {
                toast(data?.message, { type: 'success' });
                setTriggered(!triggered)
            }
            else {
                toast(data?.message, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleCoachList = () => {
        const response = fetchAllCoach();
        response.then((data) => {
            // console.log(data, "its data while fetching coach list")
            const optionsArray = data?.data?.map((item) => {
                return { value: item.id, label: item.name }
            })
            setCoachList(optionsArray)
        })
            .catch((error) => {
            })
    }

    const resetParamsFilter = (coachId) => {
        navigate(`/app/manage-clients`, {
            state: {
                coachId:null
            }
        })
    }

    const handleSetAcceptRejectModal = (status, email) => {
        const infoObject = {
            email: email,
            status: status,
        }
        setAcceptRejectUserInfo(infoObject)
        setAcceptRejectModal(true)
    }

    const handleUpdateRequestStatus = () => {
        const updateRespose = updateRequestStatus(acceptRejectUserInfo)
        setAcceptRejectModal(false);
        updateRespose.then((data) => {
            // console.log(data, "its data after status updated")
            if (data.status) {
                toast(data?.message, { type: 'success' });
                setTriggered(!triggered)
            }
            else {
                toast(somethingWrongText, { type: 'error' });
            }
        }).catch((error) => {
            toast(somethingWrongText, { type: 'error' })
        })

    }


    return (
        <>
            <CoachTabelHead
                total={(clientsList?.length !== null && clientsList?.length !== 0) ? clientsList?.length : "0"}
                userName="Clients"
                setModalShow={setModalShow}
                modalShow={modalShow}
                coachId={locationParams}
                resetParamsFilter={resetParamsFilter}
            />
            <div className={`table-wrapper-container`}>
                <div className='table-container'>
                    <div className="divTable client-table">
                        <div className="headRow">
                            <div className="divCell">Name</div>
                            <div className="divCell">Client ID</div>
                            <div className="divCell">Email</div>
                            <div className="divCell">Phone number</div>
                            <div className="divCell" onClick={() => setStatusModal(!stausModal)}>
                                Status
                                {stausModal ?
                                    <>
                                        <img src={arrow} alt="left-arrow " className=' left-arrow upWard' />
                                        <div className="profile-toast status-toast">
                                            <p onClick={() => handleFetchData()}>
                                                All
                                            </p>
                                            <p onClick={() => handleFetchData("Active")}>
                                                Active
                                            </p>
                                            <p onClick={() => handleFetchData("Inactive")}>
                                                Inactive
                                            </p>
                                        </div>
                                    </> :
                                    <img src={arrow} alt="left-arrow " className=' left-arrow downWard' />
                                }
                            </div>
                            <div className="divCell">Health Category</div>
                            <div className='divCell'>Sub Health Category</div>
                            {userRole === "333" &&
                                <>
                                    <div className="divCell">Assign Coach</div>
                                    <div className="divCell">Request Status</div>
                                    <div className="divCell"></div>
                                </>
                            }
                        </div>
                        {
                            currentRecords.length !== undefined && currentRecords.length !== 0 && currentRecords.length !== null ?
                                currentRecords?.map((item, index) => {
                                    return (
                                        <div className="divRow"
                                            key={item?.id}
                                            onClick={() => {
                                                navigate('/app/client-detail', {
                                                    state: {
                                                        id: item?.id
                                                    }
                                                })
                                            }
                                            }>
                                            <div className="divCell">{item?.name}</div>
                                            <div className="divCell">{item?.id}</div>
                                            <div className="divCell">{item?.primaryEmail}</div>
                                            <div className="divCell">{item.phoneNumber || "........."}</div>
                                            <div className="divCell">
                                                <SimpleButton
                                                    buttonText={item?.status}
                                                    buttonColor={item?.status === 'Active' ? "buttonAcive" : "buttonInactive"}
                                                    width="50%"
                                                    handleButton={(e) => {
                                                        e?.stopPropagation();
                                                        handleActiveInactive(item?.id, item?.status)
                                                    }}
                                                />
                                            </div>
                                            <div className="divCell" onClick={(e) => {
                                                e?.stopPropagation()
                                            }
                                            }>
                                                <Select isMulti={true}
                                                    isDisabled={item?.isAccepted === "Pending" ? true : false}
                                                    styles={selectStyle}
                                                    placeholder={
                                                        <div className='placeHolderStyle'>Health category</div>
                                                    }
                                                    value={
                                                        item?.HealthCategories?.map((healthCategory) => ({
                                                            label: healthCategory.name,
                                                            value: healthCategory.name,
                                                            categoryCode: healthCategory.categoryCode
                                                        }))
                                                    }
                                                    options={healthCategory}
                                                    onChange={(e) => {
                                                        selectHandler(e, item.id, index);
                                                    }}
                                                />
                                            </div>
                                            <div className="divCell" onClick={(e) => { e?.stopPropagation() }}>
                                                <Select isMulti={true} isDisabled={item?.isAccepted == 'Pending' ? true : false}
                                                    styles={selectStyle}
                                                    placeholder={
                                                        <div className='placeHolderStyle'>Sub Health Category</div>
                                                    }
                                                    value={
                                                        item?.subCategories?.map((data) => ({
                                                            label: data.name,
                                                            value: data.name,
                                                            categoryCode: data.subCategorycode
                                                        }))
                                                    }
                                                    options={getSubCategoryList(item)}
                                                    onChange={(e) => { subCategoryHandle(e, item.id, index) }}
                                                >

                                                </Select>
                                            </div>
                                            {
                                                userRole === "333" &&
                                                <>
                                                    <div className="divCell" onClick={(e) => e?.stopPropagation()}>
                                                        <Select
                                                            isDisabled={item?.isAccepted === "Pending" ? true : false}
                                                            styles={selectStyle}
                                                            placeholder={
                                                                <div className='placeHolderStyle'>Assign coach</div>
                                                            }
                                                            value={
                                                                item?.AssignCoach?.length > 0 ?
                                                                    {
                                                                        label: item?.AssignCoach[item?.AssignCoach?.length - 1]?.name,
                                                                        value: item?.AssignCoach[item?.AssignCoach?.length - 1]?.name,
                                                                    }
                                                                    :
                                                                    { label: 'Assign coach', value: 'Assign coach' }
                                                            }
                                                            options={coachList}
                                                            onChange={(e) => {
                                                                handleAssignCoach(e?.value, item?.id);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="divCell" onClick={(e) => e?.stopPropagation()}>
                                                        {item?.isAccepted === "Pending" ?
                                                            <>
                                                                <div
                                                                    className='request-status-dropdown-container'
                                                                    onClick={(e) => {
                                                                        setRequestStatusModal({ status: !requestStatusModal.status, id: item?.id })
                                                                    }
                                                                    }>
                                                                    <SimpleButton
                                                                        buttonText={item?.isAccepted}
                                                                        buttonColor="inActiveButton reset-cursor"
                                                                        width="100%"
                                                                        textColor="#F4FFF3" />
                                                                    <img
                                                                        src={arrow}
                                                                        alt="left-arrow "
                                                                        className=' left-arrow downWard' />
                                                                    {
                                                                        (requestStatusModal?.status === true && requestStatusModal?.id === item?.id) &&
                                                                        <div className="profile-toast status-toast request-status-toast">
                                                                            <p
                                                                                onClick={(e) => {
                                                                                    handleSetAcceptRejectModal("Accepted", item?.primaryEmail)
                                                                                }
                                                                                }
                                                                            >
                                                                                Accept
                                                                            </p>
                                                                            <p
                                                                                onClick={(e) => {
                                                                                    handleSetAcceptRejectModal("Rejected", item?.primaryEmail)
                                                                                }
                                                                                }

                                                                            >
                                                                                Reject
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            <SimpleButton
                                                                buttonText={item?.isAccepted}
                                                                buttonColor="buttonAcive reset-cursor"
                                                                width="100%"
                                                                handleButton={() => { }}
                                                                textColor="#F4FFF3" />
                                                        }
                                                    </div>
                                                    <div className="divCell" onClick={(e) => handleDeleteModal(e, item?.id)}>
                                                        <img className="delete" src={delteButton} alt="delete button">
                                                        </img>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <NoDataFound />
                        }
                    </div>
                </div>
            </div >
            <div className='table-pagination-container'>
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage} />
            </div>
            {
                fetDataLoader &&
                <BeatOverlayLoader
                />
            }
            <DeleteModal
                show={deleteModalShow}
                onHide={() => setDelteModalShow(false)}
                handleDelete={handleDelete}
                title="Delete Client"
                message="Are you sure, do you want to delete this client?"
            />
            <ConfirmationModal
                show={acceptRejectModal}
                onHide={() => setAcceptRejectModal(false)}
                handleFunction={() => handleUpdateRequestStatus()}
                firstButtonText="Cancel"
                secondButtonText={acceptRejectUserInfo?.status === "Accepted" ? 'Accept' : 'Reject'}
                title={acceptRejectUserInfo?.status === "Accepted" ? "Accept" : "Reject"}
                message={`Are you sure, do you want to ${acceptRejectUserInfo?.status === "Accepted" ? "Accept" : "Reject"}?`}
            />
        </>
    )
}
