import React, { useEffect, useState } from 'react'
import { CoachTabelHead } from '../../../components/headers/caochTableHead/CoachTableHead'
import '../../../styles/QuestionTable.css'
import { fetchAllQuestions } from '../../../services/common/CommonServices'
import { NoDataFound } from '../../../components/atoms/NoDataFound'
import delteButton from '../../../assets/images/Delete.png'
import { RiEditFill } from "react-icons/ri";
import { capitalizeFirstLetter } from '../../../utils/Utils'
import { Pagination } from '../../../components/modals/pagination/Pagniation'
import { deleteQuestion } from '../../../services/admin/AdminServices'
import { BeatOverlayLoader } from '../../../components/loaders/BeatOverlayLoader'
import { toast } from 'react-toastify';
import addQuestionPng from '../../../assets/images/addQuestion.png'
import { AddQuestionModal } from '../../../components/modals/addQuestionModal/AddQuestionModal'
import arrow from '../../../assets/images/Icon.svg'
import { somethingWrongText } from '../../../constants/Variables'
import { DeleteModal } from '../../../components/modals/deleteModal/DeleteModal'
import { EditQuizQuestion } from '../../../components/modals/editQuizQuestionModel/EditQuizQuestion'


export const ManageQuestions = () => {
    const [modalShow, setModalShow] = useState(false);
    const [showEditQuestionModel,setShowEditQuestionModel]=useState(false);
    const [allQuestinList, setAllQuestionList] = useState([]);
    const [triggered, setTriggered] = useState(false)
    const [fetDataLoader, setFetchDataLoader] = useState(false);
    const [questionDetailModal, setQuestionDetailModal] = useState({ status: false, id: '' });
    const [editableQuestion, setEditableQuestion] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 7;
    const [deleteModalShow, setDelteModalShow] = useState(false);
    const [delteItemId, setDeleteItemId] = useState('')



    /****** useEffect *************** */

    const userRole = localStorage.getItem('role')

    useEffect(() => {

        handleFetchAllQuestions();
    }, [])

    useEffect(() => {
        handleFetchAllQuestions();
    }, [triggered])

    /***********pagination**********/

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = allQuestinList.slice(indexOfFirstRecord,
        indexOfLastRecord);
    const nPages = Math.ceil(allQuestinList.length / recordsPerPage)

    /******helper functions****** */

    const handleFetchAllQuestions = () => {
        const response = fetchAllQuestions("paid");
        setFetchDataLoader(true)
        response.then((data) => {
            setFetchDataLoader(false)
            // console.log(data, "its data when fetch all questions")
            if (data?.status === true) {
                setAllQuestionList(data?.data.reverse())
            }
            else {
                toast(data?.message || somethingWrongText, { type: 'error' });
            }
        })
            .catch((error) => {
                setFetchDataLoader(false)
                toast(somethingWrongText, { type: 'error' });
                // console.log(error, 'its error while fetching all questions')
            })
    }

    const handleDelete = () => {
        const response = deleteQuestion(delteItemId)
        setDelteModalShow(false);
        response.then((data) => {
            if (data?.status === true) {
                toast("Question deleted successfully", { type: 'success' });
                setTriggered(!triggered)
            }
            else {
                toast(data?.message || somethingWrongText, { type: 'error' });
            }
        })
            .catch((error) => {
                toast(somethingWrongText, { type: 'error' });
            })
    }

    const handleEditButton = (questionData) => {
        // console.log(questionData, "its questino data")
        setModalShow(true)
        setEditableQuestion(questionData)
    }

    const handleQuestionDetail = (id) => {
        // console.log(id, "question detail")
        if (id === questionDetailModal.id) {
            setQuestionDetailModal({ status: !questionDetailModal.status, id: id })
        }
        else {
            setQuestionDetailModal({ status: true, id: id })
        }
    }

    const handleDeleteModal = (id,clientCount) => {
        if(clientCount==0){
            setDeleteItemId(id)
            setDelteModalShow(true)
        }
    }

    return (
        <>
            <CoachTabelHead
                total={(allQuestinList?.length !== null && allQuestinList?.length !== 0) ? allQuestinList?.length : "0"}
                userName="Questions"
                setModalShow={setModalShow}
                modalShow={modalShow}
                setShowEditQuestionModel={setShowEditQuestionModel}
                showEditQuestionModel={showEditQuestionModel}
                logo={addQuestionPng}
                text="Add New Question"
                freeQuiz='true'
                buttonClass="buttonClassforQuestion"
            />
            <div className={`table-wrapper-container`}>
                <div className='table-container'>
                    <div className="divTable question-table">
                        <div className="headRow">
                            <div className="divCell">Question ID</div>
                            <div className="divCell">Question Text</div>
                            <div className="divCell">Clients</div>
                            <div className="divCell">Detail
                            </div>
                            {userRole === "333" &&
                                <>
                                    <div className="divCell">Edit</div>
                                    <div className="divCell"></div>
                                </>
                            }
                        </div>
                        {
                            currentRecords.length !== undefined && currentRecords.length !== 0 && currentRecords.length !== null ?
                                currentRecords.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className="divRow" key={item?.questionId}>
                                                <div className="divCell">{item?.questionId}</div>
                                                <div className="divCell">{item?.question}</div>
                                                <div className="divCell">
                                                    <div className='button-sm'>
                                                        {item?.countClient} clients
                                                    </div>
                                                </div>
                                                <div className="divCell client-detail" onClick={() => handleQuestionDetail(item?.questionId)}>
                                                    <div className="button-sm">
                                                        Detail
                                                    </div>
                                                    {(questionDetailModal.status === true && questionDetailModal.id === item?.questionId) ?
                                                        <img src={arrow} alt="left-arrow " className=' left-arrow upWard' />
                                                        :
                                                        <img src={arrow} alt="left-arrow " className=' left-arrow downWard' />
                                                    }
                                                </div>
                                                {/* item?.countClient > 0 ? {} : */}
                                                {userRole === "333" &&
                                                    <>
                                                        <div className={`divCell `} onClick={() =>  handleEditButton(item)}>
                                                            <RiEditFill style={{ color: '#575757', width: '20px', height: '15px' }} />
                                                        </div>
                                                
                                                        <div className={`divCell ${item?.countClient > 0?'disabled':''}`} onClick={() => handleDeleteModal(item?.questionId,item?.countClient)}>
                                                            <img className={`delete ${item?.countClient > 0?'disabled':''}`} src={delteButton} alt="delete button">
                                                            </img>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            {
                                                questionDetailModal?.status === true && questionDetailModal?.id === item?.questionId &&
                                                <div className='question-detail-cantainer' key={item?.questionDetailModal}>
                                                    <div>
                                                        <p>Question Category:<span>
                                                            {item?.treatmentCategory}
                                                        </span>
                                                        </p>
                                                        <p>Question Type:<span>
                                                            {
                                                                capitalizeFirstLetter(item?.type)
                                                            }
                                                        </span></p>
                                                    </div>
                                                    <div className='option-container'>
                                                        {item?.options.map((item) => {
                                                            return (
                                                                <div key={item?.selectionId}>
                                                                    <div className='box'>
                                                                    </div>
                                                                    <p>
                                                                        {item?.selection}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                                :
                                <NoDataFound />
                        }
                    </div>
                </div>
            </div>
            <div className='table-pagination-container'>
                <Pagination nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage} />
            </div>
            <AddQuestionModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                editableQuestion={editableQuestion}
                setEditableQuestion={setEditableQuestion}
                triggered={triggered}
                setTriggered={setTriggered}
            />
            <DeleteModal
                show={deleteModalShow}
                onHide={() => setDelteModalShow(false)}
                handleDelete={() => handleDelete()}
                title="Delete Question"
                message="Are you sure, do you want to delete this question?"
            />
            <EditQuizQuestion
                show={showEditQuestionModel}
                onHide={()=>setShowEditQuestionModel(false)}

            />
            {
                fetDataLoader &&
                <BeatOverlayLoader />
            }
        </>
    )
}
