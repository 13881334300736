import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { Login } from "../screens/auth/Login";
import { ForgotPassword } from "../screens/auth/ForgotPassword";
import { ManageApplication } from "../screens/root/admin/ManageApplication";
import { ManageClient } from "../screens/root/admin/ManageClient";
import { ManageCoach } from "../screens/root/admin/ManageCoach";
import { ManageQuestions } from "../screens/root/admin/ManageQuestinos";
import { Root } from "./Root";
import { ResetPassword } from "../screens/common/ResetPassword";
import { ChangePassword } from "../screens/auth/ChangePassword";
import { ClientDetailPage } from "../screens/common/clientDetailPage/ClientDetailPage";
import { ManageVideos } from "../screens/common/manageVideos/ManageVideos";
import { NotificationPage } from "../screens/common/notificationPage/NotificationPage";
import PageNotFound from "../screens/common/PageNotFound";
import { AuthRoutes } from "./AuthRoutes";

const AppRoutes = () => {

  let [userInfo, setUserInfo] = useState({
    userRole: ''
  });

  useEffect(() => {
    getUserData();
  }, [])


  const getUserData = async () => {
    try {
      let role = await localStorage.getItem("role");
      setUserInfo({ ...userInfo, userRole: role })
    }
    catch {

    }
  }




  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/app" element={<Root />}>
          {/* <Route path="manage-coach" element={<ManageCoach />} />
          <Route path="manage-clients/:coachId?" element={<ManageClient />} />
          <Route path="manage-questions" element={<ManageQuestions />} />
          <Route path="client-detail" element={<ClientDetailPage />} />
          <Route path="manage-videos" element={<ManageVideos />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="manage-notification" element={<NotificationPage />} /> */}
          {/* <Route path="*" element={<PageNotFound />} /> */}

          <Route
            path="manage-app"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333"]}
                Cmp={ManageApplication}
              />
            }
          />
          <Route
            path="manage-coach"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333"]}
                Cmp={ManageCoach}
              />
            }
          />
          <Route
            path="manage-clients/:coachId?"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={ManageClient}
              />
            }
          />
          <Route
            path="manage-questions"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={ManageQuestions}
              />
            }
          />
          <Route
            path="client-detail"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={ClientDetailPage}
              />
            }
          />
          <Route
            path="manage-videos"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={ManageVideos}
              />
            }
          />
          <Route
            path="reset-password"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={ResetPassword}
              />
            }
          />
          <Route
            path="manage-notification"
            element={
              <AuthRoutes
                role={userInfo?.userRole}
                roleArray={["333", "222"]}
                Cmp={NotificationPage}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
