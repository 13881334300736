import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import '../../../styles/GlobalStyles.css'
import '../BaseModal.css'
import '../addTestimonialModal/AddTestimonialModal.css'
import './AddEditVideoTutorialsModal.css'
import { TextInputField } from '../../atoms/TextInputField';
import BeatLoader from "react-spinners/BeatLoader";
import { requiredText, somethingWrongText } from '../../../constants/Variables';
import { toast } from 'react-toastify';
import { addNewVideo, updateVideo } from '../../../services/admin/AdminServices';
import { addQuestionSelectInput, selectStyle } from '../../../styles/ReactSelectStyles';
import Select from 'react-select';
import { getHealthCategory } from '../../../services/common/CommonServices';



export const AddEditVideoTutorialsModal = ({
    show,
    onHide,
    triggered,
    editableData,
    setEditAbleData,
    healthCategory,
    nonManipuldatedHeadlCategoryData
}) => {
    const [subHealthCategoryList, setSubHealthCategoryList] = useState([]);
    const [selectedHealthCategory, setSelegedHealthCategory] = useState([]);
    const [selectedSubHealthCategory, setSelectedSubHealthCategory] = useState([])
    const [errorMessageObject, setErrorMessageObject] = useState({
        typeError: "",
        languageError: "",
        healthCategory: '',
        subHealthCategory: '',
        descriptionError: '',
        linkError: '',
    })
    const populateCategoryData=(categoryList,type)=>{
        let healthCategoryList=healthCategory?.filter((data=>categoryList?.includes(data.categoryCode)));
        return healthCategoryList
    }   
    useEffect(()=>{
        setSubCategoryList(editableData )
    },[ ])
    const setSubCategoryList=(editableData)=>{
        console.log(editableData)
        let subHealthCategoryList=nonManipuldatedHeadlCategoryData?.filter((data=>editableData.categoryCodes?.includes(data.categoryCode)))?.map((data)=>{return data.subCategory})?.flat()?.map((data)=>{
            return {
                label: data.name,
                value: data.name,
                categoryCode: data.subCategorycode
            }
        })
        let list2=healthCategory?.filter((data=>editableData.categoryCodes?.includes(data.categoryCode)));
        setSelegedHealthCategory(list2)
        let list=subHealthCategoryList?.filter((data=>editableData.subcategoryCodes?.includes(data.categoryCode)));
        setSelectedSubHealthCategory(list)
        setSubHealthCategoryList(subHealthCategoryList)
    }
    const populdateSubCategoryData=(list)=>{
        let healthCatgorlist=nonManipuldatedHeadlCategoryData?.map((data)=>{return data.subCategory})?.flat()?.filter((data=>list?.includes(data.subCategorycode)))?.map((data)=>{
            return {
                label: data.name,
                value: data.name,
                categoryCode: data.subCategorycode
            }
        })
        // video.subHealthCategoryList=healthCatgorlist
         return healthCatgorlist
        // setSelectedSubHealthCategory(healthCatgorlist)
    }
 

    let size = Object.keys(editableData).length;
    const [video, setVideo] = useState({
        type: editableData?.type || '',
        language: editableData.language || "",
        healthCategory:populateCategoryData(editableData.categoryCodes),
        subHealthCategoryList:populdateSubCategoryData(editableData.subcategoryCodes),
        description: editableData?.description || '',
        link: editableData?.link || '',
    })
    
    const [loading, setLoading] = useState(false);
    //helpers****************************************

    const hanldeInputValue = (key, e) => {
        setVideo({ ...video, [key]: e })
    }

    const handleFocus = () => {
    }

    const handleBlur = () => {
    }

    const handleHide = () => {
        setEditAbleData();
        onHide();
    }

    const validation = () => {
        if (selectedHealthCategory.length === 0) {
            setErrorMessageObject({ ...errorMessageObject, healthCategory: requiredText })
            return false;
        }
        if (selectedSubHealthCategory.length === 0) {
            setErrorMessageObject({ ...errorMessageObject, subHealthCategory: requiredText })
            return false;
        }
        if (video.description?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, descriptionError: requiredText })
            return false;
        }
        if (video.link?.trim() === "") {
            setErrorMessageObject({ ...errorMessageObject, linkError: requiredText })
            return false;
        }
        else {
            return true;
        }
    }
    const selectHealthCategory = (list) => {
        // console.log(selectedSubHealthCategory)
        if (list == 0) {
            setErrorMessageObject({ ...errorMessageObject, healthCategory: requiredText })
        } else {
            setErrorMessageObject({ ...errorMessageObject, healthCategory: '' })
        }
        console.log(selectedHealthCategory)
        const codeOfPendingItem = list?.map((element) => { return element.categoryCode });
        const previousSelectedListCode = selectedHealthCategory?.map((element) => { return element.categoryCode });
        const CodeToRemove = previousSelectedListCode?.filter((data => !codeOfPendingItem.includes(data)));
        const listOfSubCategoryOfCodeWhichIsRemoved = nonManipuldatedHeadlCategoryData?.filter((data => data.categoryCode == CodeToRemove[0]))?.[0]?.['subCategory']?.map((element) => { return element.subCategorycode });
        video.healthCategory=list
        setSelegedHealthCategory(list)
        const selectedHealthCategoryCodeList = list?.map((element) => { return element.categoryCode })
        const listOfSubHealthCategory = nonManipuldatedHeadlCategoryData?.filter((data) => selectedHealthCategoryCodeList?.includes(data.categoryCode))?.map((data) => { return data.subCategory })?.flat()?.map((element) => {
            return {
                label: element.name,
                value: element.name,
                categoryCode: element.subCategorycode
            }
        })
        setSubHealthCategoryList(listOfSubHealthCategory)
        console.log(previousSelectedListCode.length,codeOfPendingItem.length)
        if (previousSelectedListCode.length > codeOfPendingItem.length) {
            let updatedList = selectedSubHealthCategory?.filter((data => !listOfSubCategoryOfCodeWhichIsRemoved.includes(data.categoryCode)))
            // video.subHealthCategoryList=updatedList
            console.log(updatedList)
            setVideo({...video,subHealthCategoryList:updatedList})
            setSelectedSubHealthCategory(updatedList)
        }
    }
    const selectSubHealthCategory = (list) => {
        if (list == 0) {
            setErrorMessageObject({ ...errorMessageObject, subHealthCategory: requiredText })
        } else {
            setErrorMessageObject({ ...errorMessageObject, subHealthCategory: '' })
        }
        video.subHealthCategoryList=list
        setSelectedSubHealthCategory(list)
    }
    const handleSubmit = () => {
        if (validation()) {
            let data=[]
            selectedHealthCategory.forEach((element)=>{
                let allCode=nonManipuldatedHeadlCategoryData.filter((data=>data.categoryCode==element.categoryCode))[0]?.['subCategory'].map((element)=>{return element.subCategorycode})
                let items=selectedSubHealthCategory.filter((data=>allCode.includes(data.categoryCode))).map((element)=>{return element.categoryCode})
                let obj={
                    'categoryCode':element.categoryCode,
                    'subcategoryCodes':items
                }
                data.push(obj)
            })
            const requestObject = size !== 0 ? {
                videoId: editableData?.videoId,
                type: video.type,
                language: video.language,
                description: video.description,
                link: video.link,
                data:data
            } :
                {
                    type: video.type,
                    language: video.language,
                    description: video.description,
                    link: video.link,
                    data:data
                }
            const addVideoResponse = size !== 0 ? updateVideo(requestObject) : addNewVideo(requestObject);
            addVideoResponse.then((data) => {
                setLoading(false)
                // console.log(data, 'its response after testimonial')
                if (data?.status) {
                    triggered();
                    toast(data?.message, { type: 'success' });
                    setVideo(
                        {
                            typeError: "",
                            languageError: '',
                            descriptionError: '',
                            linkeError: '',
                        })
                    handleHide();
                }
                else {
                    toast(data?.message, { type: 'error' });
                }
            }).catch((error) => {
                setLoading(false)
                console.log(error, "error inthe submit testimonials")
                toast(somethingWrongText, { type: 'error' });
            })
        }
    }


    return (
        <Modal show={show} onHide={handleHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className='modal-header'>
                <Modal.Title id="contained-modal-title-vcenter justify-content-center">
                    {size !== 0 ? "Edit" : 'Add'}{" "} Video Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Add video type"
                        error={errorMessageObject.typeError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="type"
                        value={video.type}
                        style="modal-input-style"
                    />
                    <TextInputField
                        fieldType="text"
                        idfor="exampleInputName"
                        placeholder="Add video language"
                        error={errorMessageObject.languageError}
                        focusHandler={handleFocus}
                        blurHandler={handleBlur}
                        handleChange={hanldeInputValue}
                        keyName="language"
                        value={video.language}
                        style="modal-input-style "
                    />
                    <Select isMulti={true}
                        className='addQuestionSelectInput'
                        styles={addQuestionSelectInput}
                        placeholder={
                            <div className='addQuestionSelectPlaceHolderStyle'  >Health category <span style={{color:'red'}}>*</span></div>
                        }
                        value={
                            video?.healthCategory?.map((healthCategory) => ({
                                label: healthCategory.value,
                                value: healthCategory.value,
                                categoryCode: healthCategory.categoryCode
                            }))
                        }
                        options={healthCategory}
                        onChange={(e) => { selectHealthCategory(e) }}

                    />
                    {errorMessageObject?.healthCategory &&
                        <div className="error-message-style-container pb-2">

                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject?.healthCategory}
                            </small>
                        </div>
                    }
                    <div></div>
                    <Select isMulti={true}
                        className='addQuestionSelectInput'
                        styles={addQuestionSelectInput}
                        placeholder={
                            <div className='addQuestionSelectPlaceHolderStyle'  >Sub Health category <span style={{color:"red"}}>*</span> </div>
                        }
                        value={
                            video?.subHealthCategoryList?.map((healthCategory) => ({
                                label: healthCategory.value,
                                value: healthCategory.value,
                                categoryCode: healthCategory.categoryCode
                            }))
                        }
                        options={subHealthCategoryList}
                        onChange={(e) => selectSubHealthCategory(e)}

                    />
                    {errorMessageObject?.subHealthCategory &&
                        <div className="error-message-style-container pb-2">

                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject?.subHealthCategory}
                            </small>
                        </div>
                    }
                    <textarea
                        className='text-area text-area-link text-area-style'
                        name="w3review"
                        rows="4"
                        
                        onFocus={() => setErrorMessageObject({ ...errorMessageObject, descriptionError: '' })}
                        placeholder='Add video description*'
                        value={video.description}
                        onChange={(e) => hanldeInputValue("description", e.target.value)}>
                    </textarea>
                    {errorMessageObject.descriptionError &&
                        <div className="error-message-style-container pb-2">
                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject.descriptionError}
                            </small>
                        </div>
                    }
                    <textarea
                        className='text-area text-area-link desc text-area-style'
                        name="w3review"
                        rows="4"
                        onFocus={() => setErrorMessageObject({ ...errorMessageObject, linkError: '' })}
                        placeholder='Add video link'
                        value={video.link} maxLength={200}
                        onChange={(e) => hanldeInputValue("link", e.target.value)}>
                    </textarea>
                    {errorMessageObject.linkError &&
                        <div className="error-message-style-container pb-2">
                            <small className="text-danger input-label-style error-message-style">
                                {" "}
                                {errorMessageObject.linkError}
                            </small>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer className='modal-footer'>
                <Button
                    disabled={loading}
                    onClick={() => handleSubmit()}
                >
                    {loading ?
                        <BeatLoader color="#7525E8" /> :
                        size !== 0 ? "Update" : "Submit"
                    }</Button>
            </Modal.Footer>
        </Modal>
    )
}
